import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Address, Config, User } from "@pretty-green/common";
import SunStar from "assets/illustrations/characters/individual/png/Sun/PG-Character-Sun-Star.png";
import CheckoutForm from "components/CheckoutForm";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { useStateContext } from "store/configureStore";
import SignupTemplate from "templates/SignupTemplate";

// import { LoadingStatus } from "store/reducers/status";
// import { AuthType } from "store/types";

// import Button from "elements/Button";
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    checkout: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

export default () => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    // const [status, errors] = useStatus(AuthType.UpdateCurrentUser);
    const [isWorking, setIsWorking] = useState(false);
    // const isWorking = status === LoadingStatus.Loading;
    // let isWorking = false;
    const history = useHistory();
    const config: Config | null = state.config;

    // console.log(errors);

    const handleSubmit = async (stripe: any) => {
        setIsWorking(true);

        try {
            const user: User = state.auth.user || ({ displayName: "" } as User);
            const address: Address = user.address || ({} as Address);
            let { token } = await stripe.createToken({
                name: user.displayName,
                address_line1: address.line1,
                address_line2: address.line2,
                address_city: address.city,
                address_state: address.state,
                address_zip: address.postcode,
                address_country: address.country,
                currency: "aud",
            });
            console.log(token);
            const response = await actions.auth.addPayment(token.id);
            console.log(response);
            history.push("/signup/magic");
        } catch (error) {
            console.error(error);
        } finally {
            setIsWorking(false);
        }
    };

    return (
        config && (
            <SignupTemplate
                pageTitle="Almost Done."
                image={SunStar}
                imageAlt="Pretty Green"
                title="Almost Done."
                subtitle="Worry not! Your credit card will only get charged once you place an order."
            >
                <div className={classes.checkout}>
                    <StripeProvider apiKey={config.stripe.publishableKey}>
                        <Elements>
                            <CheckoutForm
                                buttonLabel="Create my account"
                                onSubmit={handleSubmit}
                                isWorking={isWorking}
                            />
                        </Elements>
                    </StripeProvider>
                </div>

                <Typography variant="body2" gutterBottom>
                    By clicking “Create my account" you agree to the{" "}
                    <a
                        href="https://www.prettygreen.com.au/pages/terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://www.prettygreen.com.au/pages/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy
                    </a>
                </Typography>
            </SignupTemplate>
        )
    );
};
