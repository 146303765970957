import React, { Fragment } from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';
import PrettyGreenLogomark from 'assets/logos/PG-Logomark-Primary.svg';
import clsx from "clsx";

type LoadingProps = {
    value: boolean,
    children?: any,
    className?: string,
    fullScreen?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignSelf: 'center',
        height: '100%'
    },
    fullScreen: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0
    },
    "@keyframes spin": {
        from: {
            transform: 'rotate(0deg)'
        },
        to: {
            transform: 'rotate(360deg)'
        },
    },
    logomark: {
        maxWidth: 300,
        height: 'auto',
        'animation-name': '$spin',
        'animation-duration': '5000ms',
        'animation-iteration-count': 'infinite',
        'animation-timing-function': 'linear',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 200
        },
    },
}));

export default ({
    value,
    children,
    className,
    fullScreen = false
}: LoadingProps) => {
    const classes = useStyles();
    const rootClassname = clsx(classes.root, className, {
        [classes.fullScreen]: fullScreen
    })

    return value ? (
        <Fragment>        
            {children && children}
        </Fragment>
    ) : (
        <div className={rootClassname}>    
            <img src={PrettyGreenLogomark} alt="Pretty Green" className={classes.logomark} />
        </div>
    );
};
