import { Entry } from "@pretty-green/common";
import { filter, indexOf, map } from "lodash";
import { EntriesType } from "store/types";

import initialState from "../initialState";

export default (state = initialState.entries, action: any) => {
    switch (action.type) {
        case EntriesType.GetEntriesRequest:
            return { ...state, data: null };

        case EntriesType.GetEntriesSuccess:
            return { ...state, data: action.data };

        case EntriesType.CreateEntrySuccess:
            return {
                ...state,
                data: [...(state.data || []), action.data],
                lastCreatedEntry: action.data,
            };

        case EntriesType.GetEntrySuccess:
            return { ...state, selectedEntry: action.data };

        case EntriesType.UpdateEntrySuccess:
            return {
                ...state,
                data: map(state.data, (e: Entry) =>
                    e.id === action.data.id ? action.data : e
                ),
                lastCreatedEntry: action.data,
            };

        case EntriesType.ResetEntry:
            return { ...state, selectedEntry: null };

        case EntriesType.DeleteEntriesSuccess:
            return {
                ...state,
                data: filter(state.data, (e: Entry) => {
                    return indexOf(action.data, e.id) === -1;
                }),
            };

        default:
            return state;
    }
};
