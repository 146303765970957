import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from 'elements/Button';
import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

type CheckoutFormProps = {
	stripe?: any;
	buttonLabel?: string;
	onSubmit: (stripe: any) => Promise<any>;
	isWorking?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	button: {
		marginTop: theme.spacing(2)
	}
}));

const CheckoutForm = ({ stripe, buttonLabel = 'Continue', onSubmit, isWorking = false }: CheckoutFormProps) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CardElement />

			<Button
				label={buttonLabel}
				working={isWorking}
				onClick={() => onSubmit(stripe)}
				className={classes.button}
			/>
		</div>
	);
};

export default injectStripe(CheckoutForm);
