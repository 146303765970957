import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Entry, UserRole } from "@pretty-green/common";
import clsx from "clsx";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import EntryDialog, { EntryDialogMode } from "components/entries/EntryDialog";
import EntryTable from "components/entries/EntryTable";
import Failure from "components/Failure";
import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useStateContext, useStatus, useUserRole } from "store/configureStore";
import { LoadingStatus } from "store/reducers/status";
import { EntriesType } from "store/types";
import DashboardTemplate from "templates/DashboardTemplate";

// import Debug from "elements/Debug";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    highlight: {
        // backgroundColor: lighten(theme.palette.primary.light, 0.85),
        // color: theme.palette.primary.main,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        flex: "1 1 100%",
    },
    fab: {
        color: theme.palette.common.white,
        flexShrink: 0,
    },
    tableWrapper: {
        overflowX: "scroll",
    },
}));

export default () => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const [status, errors] = useStatus(EntriesType.GetEntries);
    const isLoading = status === LoadingStatus.Loading;
    const entries = state.entries.data;
    // const [mode, setMode] = useState(EntryDialogMode.Create);
    // const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
        false
    );
    // const [selectedEntry, setSelectedEntry] = useState();
    const [selected, setSelected] = React.useState<string[]>([]);
    const [deleteStatus] = useStatus(EntriesType.DeleteEntries);
    const isDeleting = deleteStatus === LoadingStatus.Loading;
    const match = useRouteMatch();
    // const location = useLocation();
    const history = useHistory();
    // let params = useParams();
    const [isRole] = useUserRole();
    const superAdmin: boolean = isRole("super" as UserRole);

    const toolbarClassname = clsx(classes.toolbar, {
        [classes.highlight]: selected.length > 0,
    });

    const handleClickCreate = (): void => {
        // setMode(EntryDialogMode.Create);
        // setIsCreateDialogOpen(true);
        actions.entries.resetSelectedEntry();
        history.push(`${match!.path}/create`);
    };

    const handleCloseEntryDialog = (): void => {
        // setIsCreateDialogOpen(false);
        history.push(match!.path);
    };

    const handleClickEntry = (
        event: React.MouseEvent<unknown>,
        entry: Entry
    ): void => {
        // setMode(EntryDialogMode.Edit);
        // setSelectedEntry(entry);
        // setIsCreateDialogOpen(true);
        history.push(`${match!.path}/${entry.id}`);
    };

    const handleSelectEntries = (
        event: React.MouseEvent<unknown>,
        ids: string[]
    ): void => {
        setSelected(ids);
    };

    const handleCloseConfirmDeleteDialog = (): void => {
        setIsConfirmDeleteDialogOpen(false);
    };

    const handleConfirmDelete = (event: React.MouseEvent<unknown>): void => {
        setIsConfirmDeleteDialogOpen(true);
    };

    const handleDeleteEntries = async (
        event: React.MouseEvent<unknown>
    ): Promise<void> => {
        await actions.entries.deleteEntries(selected);
        setSelected([]);
        setIsConfirmDeleteDialogOpen(false);
    };

    useEffect(
        () => {
            actions.entries.getEntries();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        match && (
            <DashboardTemplate pageTitle="Entries" className={classes.root}>
                {/* <Debug value={{match,location, params}}/> */}
                <Failure value={errors}>
                    <Paper>
                        <Toolbar className={toolbarClassname}>
                            {superAdmin ? (
                                <Typography
                                    className={classes.title}
                                    variant="h6"
                                    id="tableTitle"
                                >
                                    Entries
                                </Typography>
                            ) : (
                                <Fragment>
                                    {selected.length > 0 ? (
                                        <Typography
                                            className={classes.title}
                                            color="inherit"
                                            variant="subtitle1"
                                        >
                                            {selected.length} selected
                                        </Typography>
                                    ) : (
                                        <Typography
                                            className={classes.title}
                                            variant="h6"
                                            id="tableTitle"
                                        >
                                            Entries
                                        </Typography>
                                    )}

                                    {selected.length > 0 ? (
                                        <Tooltip title="Delete entry">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={handleConfirmDelete}
                                            >
                                                <DeleteIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Create entry">
                                            <Fab
                                                color="secondary"
                                                aria-label="add"
                                                className={classes.fab}
                                                onClick={handleClickCreate}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Tooltip>
                                    )}
                                </Fragment>
                            )}
                        </Toolbar>

                        <div className={classes.tableWrapper}>
                            <EntryTable
                                entries={entries}
                                loading={isLoading}
                                selected={selected}
                                onClick={handleClickEntry}
                                onSelect={handleSelectEntries}
                            />
                        </div>
                    </Paper>
                </Failure>

                <ConfirmDialog
                    title="Confirm Delete"
                    message={`Are you sure you wish to delete ${
                        selected.length
                    } ${selected.length > 1 ? "entries" : "entry"}?`}
                    isOpen={isConfirmDeleteDialogOpen}
                    isWorking={isDeleting}
                    onClose={handleCloseConfirmDeleteDialog}
                    onConfirm={handleDeleteEntries}
                />
                <Switch>
                    <Route path={`${match.path}/create`}>
                        <EntryDialog
                            isOpen={true}
                            mode={EntryDialogMode.Create}
                            onClose={handleCloseEntryDialog}
                            onSuccess={handleCloseEntryDialog}
                        />
                    </Route>
                    {/* 
                    Unfortunately we can only retrieve the url params from the child, or via this render proxy.
                    See https://reacttraining.com/react-router/web/example/url-params and https://stackoverflow.com/questions/35352638/react-how-to-get-parameter-value-from-query-string
                */}
                    <Route
                        path={`${match.path}/:entryId`}
                        render={(props) => (
                            <EntryDialog
                                isOpen={true}
                                mode={EntryDialogMode.Edit}
                                entryId={props.match.params.entryId}
                                onClose={handleCloseEntryDialog}
                                onSuccess={handleCloseEntryDialog}
                            />
                        )}
                    />
                </Switch>
            </DashboardTemplate>
        )
    );
};
