import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
    EntryCreationOptions,
    EntryDietary,
    EntryOccasion,
    Gift,
    UserRole,
} from "@pretty-green/common";
import Button from "elements/Button";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useStateContext, useStatus, useUserRole } from "store/configureStore";
import { LoadingStatus } from "store/reducers/status";
import { EntriesType } from "store/types";
import * as Yup from "yup";

import Loading from "../Loading";
import EntryForm from "./EntryForm";

// import Debug from "elements/Debug";

type EntryDialogProps = {
    mode: EntryDialogMode;
    entryId?: string;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

export enum EntryDialogMode {
    Create,
    Edit,
    // View,
}

const EntryDialogModeStrings = {
    [EntryDialogMode.Create]: "Create",
    [EntryDialogMode.Edit]: "Edit",
    // [EntryDialogMode.View]: 'View',
};

const EntrySchema = Yup.object().shape({
    forename: Yup.string().required("Required"),
    surname: Yup.string().required("Required"),
    occasion: Yup.string().required("Required"),
    dietaries: Yup.array(),
    date: Yup.string().required("Required"),
    amount: Yup.number().required("Required"),
    giftId: Yup.string(),
    sameAsMyAddress: Yup.boolean(),
    line1: Yup.string().when("sameAsMyAddress", {
        is: false,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
    }),
    line2: Yup.string(),
    city: Yup.string().when("sameAsMyAddress", {
        is: false,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
    }),
    postcode: Yup.string().when("sameAsMyAddress", {
        is: false,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
    }),
    state: Yup.string().when("sameAsMyAddress", {
        is: false,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
    }),
    country: Yup.string().when("sameAsMyAddress", {
        is: false,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
    }),
    comments: Yup.string(),
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    loading: {
        padding: theme.spacing(3),
    },
}));

export default ({
    mode = EntryDialogMode.Create,
    entryId,
    isOpen = false,
    onClose,
    onSuccess,
}: EntryDialogProps) => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const [status] = useStatus(EntriesType.GetEntry);
    const isLoading = status === LoadingStatus.Loading;
    const entry = state.entries.selectedEntry;
    const [createStatus] = useStatus(EntriesType.CreateEntry);
    const [updateStatus] = useStatus(EntriesType.UpdateEntry);
    const working =
        createStatus === LoadingStatus.Loading ||
        updateStatus === LoadingStatus.Loading;
    const gifts: Gift[] | null = state.gifts.data;
    const [isRole] = useUserRole();
    const superAdmin: boolean = isRole("super" as UserRole);
    const readonly: boolean =
        (entry ? entry.status !== "active" : false) || superAdmin;

    const initialValues: EntryCreationOptions =
        mode === EntryDialogMode.Edit && entry
            ? { ...entry }
            : {
                  forename: "",
                  surname: "",
                  occasion: "" as EntryOccasion,
                  dietaries: [],
                  date: moment().format(),
                  amount: 100,
                  giftId: "",
                  sameAsMyAddress: true,
                  line1: "",
                  line2: "",
                  city: "",
                  postcode: "",
                  state: "",
                  country: "Australia",
                  comments: "",
              };

    const handleSubmit = async (
        values: EntryCreationOptions,
        formActions: any
    ) => {
        // console.log({ values, formActions });
        const attributes: any = {
            ...values,
            occasion: values.occasion as EntryOccasion,
            dietaries: values.dietaries as EntryDietary[],
        };
        if (mode === EntryDialogMode.Create) {
            await actions.entries.createEntry(attributes);
        } else if (entry) {
            await actions.entries.updateEntry(entry.id, attributes);
        }
        formActions.setSubmitting(false);
        onSuccess();
    };

    useEffect(
        () => {
            if (entryId) {
                actions.entries.getEntry(entryId);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Dialog
            aria-labelledby="entry-dialog"
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"lg"}
            disableBackdropClick
        >
            <Loading value={!isLoading} className={classes.loading}>
                <DialogTitle id="entry-dialog">
                    {EntryDialogModeStrings[mode]} Entry
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={EntrySchema}
                        onSubmit={handleSubmit}
                        render={(formikBag) => (
                            <EntryForm
                                mode={mode}
                                formikBag={formikBag}
                                gifts={gifts}
                                entry={entry}
                                readonly={readonly}
                                working={working}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        form="entryForm"
                        working={working}
                        disabled={readonly}
                    >
                        {mode === EntryDialogMode.Create ? "Create" : "Update"}
                    </Button>
                </DialogActions>
            </Loading>
        </Dialog>
    );
};
