import React from "react";
import { Select, MenuItem, InputLabel, FormHelperText, FormControl } from "@material-ui/core";
import { Field, FieldProps } from 'formik';
// import Debug from "../Debug";

type Option = {
    key: string,
    value: string,
}

// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
// }));

export default (props: any) => {
    // const classes = useStyles();

    return (
        <Field {...props}>
            {({
                field, // { name, value, onChange, onBlur }
                // form, // touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
            }: FieldProps) => {
                const { placeholder, required, margin, validate, options, ...rest } = props; // Strip out invalid TextField props

                return options && (
                    <FormControl error={!!(meta.touched && meta.error)} required={required} margin={margin}>
                        {/* <Debug value={{touched, errors, dirty, isValid, status}}/> */}
                        {placeholder && <InputLabel>{placeholder}</InputLabel>}
                        <Select
                            {...field}
                            {...rest}
                        >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}
                            {options.map(({ key, value }: Option) => (
                                <MenuItem key={value} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                        {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                    </FormControl>
                );
            }}
          </Field>
    );
}
                                