import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";

export type HeroProps = {
    title: string;
    subtitle?: string;
    tagline?: string;
    image?: string;
    imageSize?: "small" | "medium" | "large";
    imageAlt?: string;
    children?: any;
    // footer?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(8, 0, 6)
        // padding: theme.spacing(4, 0, 2)
    },
    image: {
        marginBottom: theme.spacing(2),
        maxWidth: '100%',
        height: 85,
        '&.medium': {
            height: 130,
        },
        '&.large': {
            height: 200,
        }
    },
}));

const Hero = ({
    image,
    imageSize = 'medium',
    imageAlt,
    title,
    subtitle,
    children
}: HeroProps) => {
    const classes = useStyles();

    const imageClassname = clsx(classes.image, imageSize);

    return (
        <React.Fragment>
            {/* <Container maxWidth="sm" component="main" className={classes.hero}>
                {children}
                <Typography
                    component="h1"
                    variant="h6"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    {title}
                </Typography>
                {tagline && (
                    <Typography
                        variant="h5"
                        align="center"
                        color="textSecondary"
                        component="p"
                    >
                        {tagline}
                    </Typography>
                )}
            </Container> */}
            <Container maxWidth="sm" component="main" className={classes.hero}>
                {image && <img src={image} alt={imageAlt} className={imageClassname} />}
                
                {title && <Typography
                    component="h1"
                    variant="h3"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    {title}
                </Typography>}
                
                {subtitle && (
                    <Typography
                        component="h2"
                        variant="h4"
                        align="center"
                        color="textPrimary"
                    >
                        {subtitle}
                    </Typography>
                )}
            </Container>
        </React.Fragment>
    );
};

export default Hero;
