"use strict";
exports.__esModule = true;
exports.sleep = exports.ONE_HOUR_SECONDS = exports.ONE_MINUTE_SECONDS = exports.ONE_DAY_MS = exports.ONE_HOUR_MS = exports.ONE_MINUTE_MS = exports.ONE_SECOND_MS = void 0;
exports.ONE_SECOND_MS = 1000;
exports.ONE_MINUTE_MS = 60 * exports.ONE_SECOND_MS;
exports.ONE_HOUR_MS = 60 * exports.ONE_MINUTE_MS;
exports.ONE_DAY_MS = exports.ONE_HOUR_MS * 24;
exports.ONE_MINUTE_SECONDS = 60;
exports.ONE_HOUR_SECONDS = 60 * exports.ONE_MINUTE_SECONDS;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
exports.sleep = function (durationMs) { return new Promise(function (resolve) { return setTimeout(resolve, durationMs); }); };
