import { Gift } from "@pretty-green/common";
import apiClient from "utils/api";

export default {
    getGifts: async (): Promise<Gift[]> => {
        const { data } = await apiClient.get("/gifts");
        return data;
    },

    getGift: async (id: string): Promise<Gift> => {
        const { data } = await apiClient.get(`/gifts/${id}`);
        return data;
    },
};
