import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import BerryEgg from 'assets/illustrations/characters/individual/png/Berry/PG-Character-Berry-Egg.png';
import clsx from 'clsx';
import Button from 'elements/Button';
import React from 'react';
import SignupTemplate from 'templates/SignupTemplate';

// import WarningIcon from '@material-ui/icons/Warning';

type UnhealthyProps = {
	className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row'
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	messages: {},
	message: {},
	button: {
		marginTop: theme.spacing(4)
	}
}));

export default ({ className }: UnhealthyProps) => {
	const classes = useStyles();
	const rootClassname = clsx(classes.root, className);

	const handleRefresh = () => {
		window.location.reload();
	};

	return (
		<SignupTemplate
			pageTitle="Something went wrong."
			image={BerryEgg}
			imageAlt="Something went wrong."
			useHeader={false}
		>
			<div className={rootClassname}>
				<ErrorIcon className={classes.icon} />
				<div className={classes.messages}>
					<Typography>Gosh, looks like something went wrong. Sorry about that!</Typography>
				</div>
			</div>
			<Button onClick={handleRefresh} className={classes.button}>
				Try again
			</Button>
		</SignupTemplate>
	);
};
