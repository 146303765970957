import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
// import { Link as RouterLink } from "react-router-dom";
import { Field, FieldProps } from 'formik';
// import Debug from "../Debug";

// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
//     form: {},
//     textField: {},
// }));

// interface FormValues {
//     displayName: string;
//     businessName?: string;
// }

// const Component = ({ field, form, meta, ...props }: { field:any, form:any, meta: any }) => {
//     return (
//         <div>
//             <TextField {...field} {...props} />
//             {meta.touched && meta.error && meta.error}
//         </div>
//     );
// };

export default (props: any) => {
    // const classes = useStyles();

    return (
        <Field {...props}>
            {({
                field, // { name, value, onChange, onBlur }
                // form, // touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
            }: FieldProps) => {
                const { validate, ...rest } = props; // Strip out invalid TextField props
                return (
                    <Fragment> 
                        {/* <Debug value={{touched, errors, dirty, isValid, status}}/> */}
                        <TextField
                            {...field}
                            {...rest}
                            error={!!(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error && meta.error}
                        />
                        {/* {meta.touched && meta.error && meta.error} */}
                    </Fragment>
                );
            }}
          </Field>
    );
}
                                