import Button from 'elements/Button';
import Debug from 'elements/Debug';
import React, { createContext, Fragment, useContext, useReducer } from 'react';

// import { createStore, Store } from 'redux';

const initialState = {
	count: 0
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'INCREMENT':
			return { ...state, count: state.count + 1 };
		default:
			return state;
	}
};

const useActions = (dispatch: any, state: any) => ({
	increment: async () => {
		try {
			dispatch({ type: 'INCREMENT' });
			// const user: User = await authClient.login(accessToken);
		} catch (error) {}
	}
});

// const store: Store = createStore(reducer, initialState);

const dispatchPrototype = (prop: any): void => {};
const ReduxContext = createContext({
	state: initialState,
	dispatch: dispatchPrototype,
	actions: useActions(dispatchPrototype, initialState)
});

// const useStateValue = () => useContext(ReduxContext);

export const Consumer = () => {
	const { state, actions } = useContext(ReduxContext);
	// const { state, dispatch, actions } = useContext(ReduxContext);
	console.log(actions);

	const handleClickSetState = () => {
		// dispatch({ type: 'INCREMENT' });
		actions.increment();
	};

	const handleClickGetState = () => {
		console.log(state);
	};

	return (
		<Fragment>
			{/* <Debug value={context} /> */}
			<Debug value={state} />
			<Button label="Set State" onClick={handleClickSetState} />
			<Button label="Get State" onClick={handleClickGetState} />
		</Fragment>
	);
};

export default () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const actions = useActions(dispatch, state);

	console.log(state);

	return (
		<ReduxContext.Provider value={{ state, dispatch, actions }}>
			<Debug value={state} />
			<Consumer />
		</ReduxContext.Provider>
	);
};
