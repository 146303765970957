import { AuthType } from 'store/types';

import initialState from '../initialState';

export default (state = initialState.auth, action: any) => {
	switch (action.type) {
		case AuthType.LoginRequest:
			return { ...state, isAuthenticating: true };

		case AuthType.LoginFailure:
			return {
                isAuthenticated: false,
                isAuthenticating: false,
                user: null
            };

		case AuthType.LoginSuccess:
		case AuthType.GetCurrentUserSuccess:
			return {
				...state,
				isAuthenticating: false,
				isAuthenticated: true,
				user: action.data
			};

		case AuthType.GetCurrentUserFailure:
			return {
				...state,
				isAuthenticating: false
			};

		case AuthType.UpdateCurrentUserSuccess:
			return {
				...state,
				user: action.data
			};

		case AuthType.LogoutSuccess:
			return {
				...state,
				isAuthenticated: false,
				user: null
			};

		default:
			return state;
	}
};