import { createStyles, makeStyles, Theme } from '@material-ui/core';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute';
import Contact from 'pages/Contact';
import Entries from 'pages/dashboard/Entries';
import Schedule from 'pages/dashboard/Schedule';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import TechAuth from 'pages/tech/Auth';
import TechContext from 'pages/tech/Context';
import TechRedux from 'pages/tech/Redux';
import TechState from 'pages/tech/State';
import Auth from 'pages/user/Auth';
import Logout from 'pages/user/Logout';
import SignupAddress from 'pages/user/signup/Address';
import SignupMagic from 'pages/user/signup/Magic';
import SignupPayment from 'pages/user/signup/Payment';
import Signup from 'pages/user/signup/Signup';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Tech
// Public
// User
// Signup
// Dashboard
// @todo move this to app?
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		'@global': {
			body: {
				backgroundColor: theme.palette.common.white
			},
			a: {
				textDecoration: 'none'
			}
		},
		root: {
			// flexGrow: 1,
		}
		// container: {
		//     padding: theme.spacing(4, 0, 4),
		// }
	})
);

export default () => {
	const classes = useStyles();
	const local = process.env.NODE_ENV !== 'production';

	return (
		<Router>
			<div className={classes.root}>
				<Switch>
					{/* Public */}
					<Route exact path="/" component={Home} />
					<Route exact path="/contact" component={Contact} />
					<UnauthenticatedRoute exact path="/login" component={Auth} />
					<Route exact path="/logout" component={Logout} />

					{/* Signup */}
					<AuthenticatedRoute exact path="/signup" component={Signup} />
					<AuthenticatedRoute exact path="/signup/address" component={SignupAddress} />
					<AuthenticatedRoute exact path="/signup/payment" component={SignupPayment} />
					<AuthenticatedRoute exact path="/signup/magic" component={SignupMagic} />

					{/* Dashboard */}
					<AuthenticatedRoute exact path="/schedule" component={Schedule} />
					<AuthenticatedRoute path="/entries" component={Entries} />

					{/* Tech (Local) */}
					{local && <Route exact path="/tech/auth" component={TechAuth} />}
					{local && <Route exact path="/tech/context" component={TechContext} />}
					{local && <Route exact path="/tech/redux" component={TechRedux} />}
					{local && <Route exact path="/tech/state" component={TechState} />}

					{/* Page Not Found */}
					<Route component={NotFound} />
				</Switch>
			</div>
		</Router>
	);
};
