import { Typography } from '@material-ui/core';
import Hero from 'components/layout/Hero';
import React from 'react';
import PublicTemplate from 'templates/PublicTemplate';

// import { makeStyles, Theme } from '@material-ui/core/styles';
// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
// }));

export default () => {
	// const classes = useStyles();

	return (
		<PublicTemplate pageTitle="Contact Us">
			<Hero title="Contact Us" subtitle="Give us a shout!" />

			<Typography component="h1" variant="h6" align="center" color="textPrimary" gutterBottom>
				info@prettygreen.com.au
			</Typography>
		</PublicTemplate>
	);
};
