import axios from 'axios';

// axios.defaults.headers.common.Authorization = null;
// delete axios.defaults.headers.common.Authorization;

const createApiClient = () => {
	const client = axios.create({
		headers: {
			common: {},
			'Content-Type': 'application/json',
			Accept: 'application/json',
			data: {}
		},
		withCredentials: true
	});

	client.defaults.baseURL = '/api/v1';

	// // @todo - better way of doing management of axios error response
	// client.interceptors.response.use(
	//     response => {
	//         return response;
	//     },
	//     error => {
	//         return Promise.reject(error.response);
	//     }
	// );

	// console.log('API host', client.defaults.baseURL);
	return client;
};

export default createApiClient();
