import { User, UserRole } from "@pretty-green/common";
import React, { createContext, useContext, useReducer } from "react";

import createActions from "./actions/root";
import initialState from "./initialState";
import rootReducer from "./reducers/root";

export const hasRole = (user: User, role: UserRole): boolean => {
    const roles: UserRole[] = user && user.roles ? user.roles : [];
    return roles.indexOf(role) !== -1;
};

export const StateContext = createContext({
    state: { ...initialState },
    dispatch: (prop: any): void => {},
    actions: createActions((prop: any): void => {}, {}),
});

export const StateProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(rootReducer, initialState);
    const actions = createActions(dispatch, state);

    // console.log({ state, actions });

    return (
        <StateContext.Provider value={{ state, dispatch, actions }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);

export const useStatus = (name: string) => {
    const { state } = useStateContext();
    const status = state.status[name];
    const errors = state.errors[name];
    return [status, errors];
};

export const useUserRole = () => {
    const { state } = useStateContext();
    const isRole = (role: UserRole): boolean => {
        return hasRole(state.auth.user || ({} as User), role);
    };
    return [isRole];
};
