import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Donut from 'assets/illustrations/characters/individual/png/Berry/PG-Character-Berry-Donut.png';
import Hero from 'components/layout/Hero';
import Button from 'elements/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import PublicTemplate from 'templates/PublicTemplate';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
}));

export default () => {
	const classes = useStyles();

	return (
		<PublicTemplate pageTitle="Page Not Found" className={classes.root}>
			<Hero title="Page Not Found" tagline="Oops!" image={Donut} imageAlt="Pretty Green" />

			<Typography variant="h5" align="center" color="textSecondary" component="p">
				Didn't mean to?
			</Typography>

			<Button component={Link} to="/">
				Go home
			</Button>
		</PublicTemplate>
	);
};
