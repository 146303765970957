"use strict";
exports.__esModule = true;
exports.UserRole = exports.EntryDietary = exports.EntryOccasion = exports.EntryStatus = exports.cookieKey = exports.foo = void 0;
exports.foo = "bar";
// Firebase strips out client side cookies, except `__session`
exports.cookieKey = "__session";
// export type EntryStatus = 'active' | 'inactive' | 'dispatched';
// export type EntryOccassion = 'birthday' | 'anniversary' | 'new_years_greeting' | 'merry_christmas' | 'valentines_day' | 'australia_day' | 'happy_easter' | 'new_baby' | 'thank_you' | 'promotion' | 'first_day_at_work';
// export type EntryDietary = 'vegan' | 'vegetarian' | 'celiac_gluten_free' | 'lactose_intolerant_dairy_free' | 'no_alcohol' | 'diabetic';
var EntryStatus;
(function (EntryStatus) {
    EntryStatus["Active"] = "active";
    EntryStatus["Inactive"] = "inactive";
    EntryStatus["Dispatched"] = "dispatched";
})(EntryStatus = exports.EntryStatus || (exports.EntryStatus = {}));
// export const EntryStatusStrings = {
//     [EntryStatus.Active]: 'Active',
//     [EntryStatus.Inactive]: 'Inactive',
//     [EntryStatus.Dispatched]: 'Dispatched',
// };
var EntryOccasion;
(function (EntryOccasion) {
    EntryOccasion["Birthday"] = "birthday";
    EntryOccasion["Anniversary"] = "anniversary";
    EntryOccasion["NewYearsGreeting"] = "new_years_greeting";
    EntryOccasion["MerryChristmas"] = "merry_christmas";
    EntryOccasion["ValentinesDay"] = "valentines_day";
    EntryOccasion["AustraliaDay"] = "australia_day";
    EntryOccasion["HappyEaster"] = "happy_easter";
    EntryOccasion["NewBaby"] = "new_baby";
    EntryOccasion["ThankYou"] = "thank_you";
    EntryOccasion["Promotion"] = "promotion";
    EntryOccasion["FirstDayAtWork"] = "first_day_at_work";
})(EntryOccasion = exports.EntryOccasion || (exports.EntryOccasion = {}));
// export const EntryOccasionStrings: any = {
//     [EntryOccasion.Birthday]: 'Birthday',
//     [EntryOccasion.Anniversary]: 'Anniversary',
//     [EntryOccasion.NewYearsGreeting]: 'New Years Greeting',
//     [EntryOccasion.MerryChristmas]: 'Merry Christmas',
//     [EntryOccasion.ValentinesDay]: 'Valentines Day',
//     [EntryOccasion.AustraliaDay]: 'Australia Day',
//     [EntryOccasion.HappyEaster]: 'Happy Easter',
//     [EntryOccasion.NewBaby]: 'New Baby',
//     [EntryOccasion.ThankYou]: 'Thank You',
//     [EntryOccasion.Promotion]: 'Promotion',
//     [EntryOccasion.FirstDayAtWork]: 'First Day At Work',
// }
var EntryDietary;
(function (EntryDietary) {
    EntryDietary["Vegan"] = "vegan";
    EntryDietary["Vegetarian"] = "vegetarian";
    EntryDietary["Celiac"] = "celiac";
    EntryDietary["LactoseIntolerant"] = "lactose_intolerant";
    EntryDietary["NoAlcohol"] = "no_alcohol";
    EntryDietary["Diabetic"] = "diabetic";
})(EntryDietary = exports.EntryDietary || (exports.EntryDietary = {}));
var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    UserRole["SuperAdmin"] = "super";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
