import { User } from "@pretty-green/common";
import { Dispatch } from "redux";
import authClient from "services/auth";
import { AuthType } from "store/types";

const LOGIN_REQUEST = () => ({ type: AuthType.LoginRequest });
const LOGIN_SUCCESS = (user: User) => ({
    type: AuthType.LoginSuccess,
    data: user,
});
const LOGIN_FAILURE = (error: any) => ({
    type: AuthType.LoginFailure,
    data: error,
});

const LOGOUT_REQUEST = () => ({ type: AuthType.LogoutRequest });
const LOGOUT_SUCCESS = () => ({ type: AuthType.LogoutSuccess });
const LOGOUT_FAILURE = (error: any) => ({
    type: AuthType.LogoutFailure,
    data: error,
});

const GET_CURRENT_USER_REQUEST = () => ({
    type: AuthType.GetCurrentUserRequest,
});
const GET_CURRENT_USER_SUCCESS = (user: User) => ({
    type: AuthType.GetCurrentUserSuccess,
    data: user,
});
const GET_CURRENT_USER_FAILURE = (error: any) => ({
    type: AuthType.GetCurrentUserFailure,
    data: error,
});

const UPDATE_CURRENT_USER_REQUEST = () => ({
    type: AuthType.UpdateCurrentUserRequest,
});
const UPDATE_CURRENT_USER_SUCCESS = (user: User) => ({
    type: AuthType.UpdateCurrentUserSuccess,
    data: user,
});
const UPDATE_CURRENT_USER_FAILURE = (error: any) => ({
    type: AuthType.UpdateCurrentUserFailure,
    data: error,
});

const ADD_PAYMENT_REQUEST = () => ({ type: AuthType.AddPaymentRequest });
const ADD_PAYMENT_SUCCESS = () => ({
    type: AuthType.AddPaymentSuccess,
    data: {},
});
const ADD_PAYMENT_FAILURE = (error: any) => ({
    type: AuthType.AddPaymentFailure,
    data: error,
});

export default (dispatch: Dispatch, state: any) => ({
    login: async (accessToken: string): Promise<void> => {
        try {
            dispatch(LOGIN_REQUEST());
            const user: User = await authClient.login(accessToken);
            dispatch(LOGIN_SUCCESS(user));
        } catch (error) {
            console.error(error);
            dispatch(LOGIN_FAILURE(error));
        }
    },

    logout: async (): Promise<void> => {
        try {
            dispatch(LOGOUT_REQUEST());
            await authClient.logout();
            dispatch(LOGOUT_SUCCESS());
        } catch (error) {
            console.error(error);
            dispatch(LOGOUT_FAILURE(error));
        }
    },

    getCurrentUser: async (): Promise<void> => {
        try {
            console.log("Getting current user");
            dispatch(GET_CURRENT_USER_REQUEST());
            const user: User = await authClient.getCurrentUser();
            dispatch(GET_CURRENT_USER_SUCCESS(user));
        } catch (error) {
            dispatch(GET_CURRENT_USER_FAILURE(error));
        }
    },

    updateCurrentUser: async (attributes: Partial<User>): Promise<void> => {
        try {
            dispatch(UPDATE_CURRENT_USER_REQUEST());
            const user: User = await authClient.updateCurrentUser(attributes);
            console.log({ attributes, user });
            dispatch(UPDATE_CURRENT_USER_SUCCESS(user));
        } catch (error) {
            console.error(error);
            dispatch(UPDATE_CURRENT_USER_FAILURE(error));
        }
    },

    addPayment: async (token: any): Promise<void> => {
        try {
            dispatch(ADD_PAYMENT_REQUEST());
            const response: any = await authClient.addPayment(token);
            console.log(response);
            dispatch(ADD_PAYMENT_SUCCESS());
        } catch (error) {
            console.error(error);
            dispatch(ADD_PAYMENT_FAILURE(error));
        }
    },
});

// export default ({ dispatch, getState }: { dispatch: Dispatch, getState: () => any }) => ({

//     login: async (accessToken: string) => {
//         try {
//             dispatch(LOGIN_REQUEST());
//             const user: User = await authClient.login(accessToken);
//             dispatch(LOGIN_SUCCESS(user));
//         } catch (error) {
//             // dispatch(actions.logoutFailure(error));
//         }
//     },

//     getCurrentUser: async () => {
//         try {
//             dispatch(GET_CURRENT_USER_REQUEST());
//             const response = await authClient.getCurrentUser();
//             console.log(response);
//             // const user = await api.fetchAuthUser();
//             // dispatch(actions.loginSuccess(user));
//         } catch (error) {
//             // dispatch(actions.logoutFailure(error));
//         }
//     }

// });

// import {
//   AUTH_FETCH_USER_REQUEST,
//   AUTH_FETCH_USER_SUCCESS,
//   AUTH_FETCH_USER_FAILURE,
//   AUTH_FETCH_ACCOUNT_REQUEST,
//   AUTH_FETCH_ACCOUNT_SUCCESS,
//   AUTH_FETCH_ACCOUNT_ERROR,
//   AUTH_UPDATE_USER_REQUEST,
//   AUTH_UPDATE_USER_SUCCESS,
//   AUTH_UPDATE_USER_FAILURE,
//   AUTH_LOGIN_REQUEST,
//   AUTH_LOGIN_SUCCESS,
//   AUTH_LOGIN_FAILURE,
//   AUTH_LOGOUT_REQUEST,
//   AUTH_LOGOUT_SUCCESS,
//   AUTH_LOGOUT_FAILURE,
// } from '../types';

// // import * as api from 'api/authUser';

// const actions = {
//   fetchAuthUserRequest: () => ({ type: AUTH_FETCH_USER_REQUEST }),
//   fetchAuthUserSuccess: data => ({ type: AUTH_FETCH_USER_SUCCESS, data }),
//   fetchAuthUserFailure: error => ({ type: AUTH_FETCH_USER_FAILURE, error }),
//   fetchAccountRequest: () => ({
//     type: AUTH_FETCH_ACCOUNT_REQUEST,
//   }),
//   fetchAccountSuccess: data => ({
//     type: AUTH_FETCH_ACCOUNT_SUCCESS,
//     data,
//   }),
//   fetchAccountFailure: error => ({
//     type: AUTH_FETCH_ACCOUNT_ERROR,
//     error,
//   }),
//   updateAuthUserRequest: () => ({ type: AUTH_UPDATE_USER_REQUEST }),
//   updateAuthUserSuccess: data => ({ type: AUTH_UPDATE_USER_SUCCESS, data }),
//   updateAuthUserFailure: error => ({ type: AUTH_UPDATE_USER_FAILURE, error }),
//   loginRequest: () => ({ type: AUTH_LOGIN_REQUEST }),
//   loginSuccess: data => ({ type: AUTH_LOGIN_SUCCESS, data }),
//   loginFailure: error => ({ type: AUTH_LOGIN_FAILURE, error }),
//   logoutRequest: () => ({ type: AUTH_LOGOUT_REQUEST }),
//   logoutSuccess: () => ({ type: AUTH_LOGOUT_SUCCESS }),
//   logoutFailure: error => ({ type: AUTH_LOGOUT_FAILURE, error }),
// };

// /**
//  * Login
//  */
// export const login = fbResponse => async dispatch => {
//   try {
//     dispatch(actions.loginRequest());
//     await api.login(fbResponse.accessToken);
//     const user = await api.fetchAuthUser();
//     dispatch(actions.loginSuccess(user));
//   } catch (error) {
//     dispatch(actions.logoutFailure(error));
//   }
// };

// /**
//  * Fetch Auth User
//  */
// export const fetchAuthUser = () => async dispatch => {
//   try {
//     dispatch(actions.fetchAuthUserRequest());
//     const authUser = await api.fetchAuthUser();
//     dispatch(actions.fetchAuthUserSuccess(authUser));
//   } catch (error) {
//     dispatch(actions.fetchAuthUserFailure(error));
//   }
// };

// /**
//  * Update Auth User
//  */
// export const updateAuthUser = attributes => async dispatch => {
//   try {
//     dispatch(actions.updateAuthUserRequest());
//     await api.updateAuthUser(attributes);
//     dispatch(actions.updateAuthUserSuccess(attributes));
//   } catch (error) {
//     dispatch(actions.updateAuthUserFailure(error));
//   }
// };

// /**
//  * Fetch Auth User Account
//  */
// export const fetchAccount = () => async dispatch => {
//   try {
//     dispatch(actions.fetchAccountRequest());
//     const authUser = await api.fetchAccount();
//     dispatch(actions.fetchAccountSuccess(authUser));
//   } catch (error) {
//     dispatch(actions.fetchAccountFailure(error));
//   }
// };

// /**
//  * Logout
//  */
// export const logout = () => async dispatch => {
//   try {
//     dispatch(actions.logoutRequest());
//     const status = await api.logout();
//     dispatch(actions.logoutSuccess(status));
//   } catch (error) {
//     dispatch(actions.logoutFailure(error));
//   }
// };
