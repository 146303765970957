import { Config, Entry, Gift, User } from "@pretty-green/common";

import { LoadingStatus } from "./reducers/status";

interface AuthState {
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    user: User | null;
}

interface EntriesState {
    data: Entry[] | null;
    selectedEntry: Entry | null;
    lastCreatedEntry: Entry | null;
}

interface GiftsState {
    data: Gift[] | null;
}

interface LoadingState {
    [key: string]: boolean;
}

interface StatusState {
    [key: string]: LoadingStatus;
}

interface ErrorState {
    [key: string]: any;
}

export interface State {
    config: Config | null;
    auth: AuthState;
    entries: EntriesState;
    gifts: GiftsState;
    loading: LoadingState;
    status: StatusState;
    errors: ErrorState;
}

const initialState: State = {
    config: null,
    auth: {
        isAuthenticated: false,
        isAuthenticating: true,
        user: null,
    },
    entries: {
        data: null,
        lastCreatedEntry: null,
        selectedEntry: null,
    },
    gifts: {
        data: null,
    },
    loading: {},
    status: {},
    errors: {},
};

export default initialState;
