import React, { Fragment } from 'react';
import moment from 'moment';

type DateProps = {
    value: string,
    format?: string
}

export default ({
    value,
    format = 'ddd, Do MMM YYYY'
}: DateProps) => {
    return (<Fragment>{moment(value).format(format)}</Fragment>);
};