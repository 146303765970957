import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Header from 'components/nav/Header';
import Meta from 'elements/Meta';
import React from 'react';

// import Footer from "components/nav/Footer";

export type PublicTemplateProps = {
	pageTitle: string;
	className?: string;
	children: any;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	slot: {
		marginTop: theme.spacing(4)
	}
}));

export default ({ pageTitle, className, children }: PublicTemplateProps) => {
	const classes = useStyles();

	const slotClassname = clsx(classes.slot, className);

	return (
		<div className={classes.root}>
			<Meta title={pageTitle} />

			<Header />

			<div className={slotClassname}>{children}</div>

			{/* <Footer /> */}
		</div>
	);
};
