import {
    Entry,
    EntryCreationOptions,
    GetEntriesParams,
} from "@pretty-green/common";
import apiClient from "utils/api";
import { asyncForEach } from "utils/async";

export default {
    getEntries: async (params?: GetEntriesParams): Promise<Entry[]> => {
        const { data } = await apiClient.get("/entries", { params });
        return data;
    },

    createEntry: async (attributes: EntryCreationOptions): Promise<Entry> => {
        const { data } = await apiClient.post("/entries", attributes);
        return data;
    },

    getEntry: async (id: string): Promise<Entry> => {
        const { data } = await apiClient.get(`/entries/${id}`);
        return data;
    },

    updateEntry: async (
        id: string,
        attributes: Partial<Entry>
    ): Promise<Entry> => {
        const { data } = await apiClient.put(`/entries/${id}`, attributes);
        return data;
    },

    deleteEntries: async (ids: string[]): Promise<any> => {
        await asyncForEach(ids, async (id: string) => {
            return await apiClient.delete(`/entries/${id}`);
        });
        // const { data } = await apiClient.delete(`/entries`, { ids });
        // return data;
    },
};
