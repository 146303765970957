import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import clsx from "clsx";

type RowProps = {
    className?: string;
    children: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

export default ({
    className,
    children
}: RowProps) => {
    const classes = useStyles();
    const rootClassname = clsx(classes.root, className);

    return (
        <div className={rootClassname}>
            {children}
        </div>
    );
};
