import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/styles";
import { Theme, Container } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
    },
    slot: {}
}));

export default ({ className, children }: { className?: string, children: any }) => {
    const classes = useStyles();

    const rootClassname = clsx(classes.root, className);
    // const slotClassname = clsx(classes.slot, className);

    return (
        <Container maxWidth="sm" className={rootClassname}>
            {/* <div className={slotClassname}> */}
            {children}
            {/* </div> */}
        </Container>
    );
};
