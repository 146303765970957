import React from 'react';
import { useStateContext } from 'store/configureStore';
import { RouteProps, Route, Redirect } from 'react-router';
import { querystring } from 'utils/url';

export default ({ component, ...rest }: RouteProps) => {
	const { state } = useStateContext();
	const redirect = querystring('redirect');

	return state.auth.isAuthenticated ? (
		<Route component={component} {...rest} />
	) : (
		<Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
	);
};
