import Button from 'elements/Button';
import Debug from 'elements/Debug';
import React, { createContext, Fragment, useContext, useState } from 'react';

export const TestContext = createContext({ count: 1, incremenentCount: () => {} });

export const Consumer = () => {
	const context = useContext(TestContext);

	const handleClickSetState = () => {
		context.incremenentCount();
	};

	const handleClickGetState = () => {
		console.log(context);
	};

	return (
		<Fragment>
			<Debug value={context} />
			<Button label="Set State" onClick={handleClickSetState} />
			<Button label="Get State" onClick={handleClickGetState} />
		</Fragment>
	);
};

export default () => {
	const [count, setCount] = useState(0);

	const incremenentCount = () => {
		setCount(count + 1);
	};

	return (
		<TestContext.Provider value={{ count, incremenentCount }}>
			<Debug value={count} />
			<Consumer />
		</TestContext.Provider>
	);
};
