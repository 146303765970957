import { makeStyles, Theme } from '@material-ui/core';
import SunJar from 'assets/illustrations/characters/individual/png/Sun/PG-Character-Sun-Jar.png';
import PrettyGreenLogomark from 'assets/logos/PG-Logomark-Primary.svg';
import Loading from 'components/Loading';
import React, { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useHistory } from 'react-router-dom';
import { useStateContext } from 'store/configureStore';
import SignupTemplate from 'templates/SignupTemplate';

// import useWindowSize from 'react-use/lib/useWindowSize'
// import Debug from "elements/Debug";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    loading: {
        marginTop: 20
    },
    imageRoot: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignSelf: 'center',
        height: '100%'
    },
    logomark: {
        marginTop: 20,
        maxWidth: 300,
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 200
        },
    },
}));

export default () => {
    const classes = useStyles();
    const history = useHistory();
    const { actions } = useStateContext();
    // const { state, actions } = useStateContext();
    // const { width, height } = useWindowSize();
    const [hideLoading, setHideLoading] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const canvasRef = useRef(null);

    const subtitle = !hideLoading ? "We’re creating your Pretty Green account. Done in a flash!" : "Account created. Get ready for the fun!";

    useEffect(
        () => {
            const onboardUser = async () => {
                await actions.auth.updateCurrentUser({
                    isOnboarded: true
                });
                setHideLoading(true);
                setShowConfetti(true);
                setTimeout(() => {
                    history.push('/');
                }, 4000);
            };
            onboardUser();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <SignupTemplate
            pageTitle="Magic in progress."
            image={SunJar}
            imageAlt="Pretty Green"
            title="Magic in progress."
            subtitle={subtitle}
        >
            <Confetti numberOfPieces={200} recycle={true} canvasRef={canvasRef} run={showConfetti} />
            <Loading value={hideLoading} className={classes.loading}>
                <div className={classes.imageRoot}>
                    <img src={PrettyGreenLogomark} alt="Pretty Green" className={classes.logomark} />
                </div>
            </Loading>
        </SignupTemplate>
    );
};
