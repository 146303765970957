import './Calendar.css';

import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '../Loading';

// import timeGridPlugin from '@fullcalendar/timegrid';
// import Debug from 'elements/Debug';

interface CalendarProps {
	loading?: boolean;
	events: EventInput[];
	className?: string;
}

interface HeaderProps {
	left: string;
	center: string;
	right: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {}
}));

export default ({ loading, events, className }: CalendarProps) => {
	const classes = useStyles();
	const rootClassName = clsx(classes.root, className);
	const history = useHistory();
	const calendarComponentRef: any = React.createRef<FullCalendar>();

	const getHeaderProps = (): HeaderProps => {
		return {
			left: '',
			center: 'prev,title,next',
			right: window.innerWidth < 765 ? '' : 'dayGridMonth,listWeek'
		};
	};

	const handleDateClick = () => {};

	const handleEventRender = (data: any) => {
		if (data.view.type === 'dayGridMonth') {
			const { el, event: { title, extendedProps } } = data;
			const { content } = extendedProps;
			const imageHTML: string = ''; //image ? `<img src="${image}" alt="${title}" class="fc-event-image" />` : '';
			const contentHTML: string = content ? `<div class="fc-event-content">${content}</div>` : '';
			el.innerHTML = `
                <div class="fc-event-root">
                    <div class="fc-event-title" title="${title}">${title}</div>
                    ${imageHTML}
                    ${contentHTML}
                </div>
            `;
		}
	};

	const handleEventClick = (eventClickInfo: any) => {
		const { event: { id } } = eventClickInfo;
		history.push(`/entries/${id}`);
	};

	const handleWindowResize = (view: any) => {
		const calendar = calendarComponentRef.current.getApi();
		calendar.changeView(window.innerWidth < 765 ? 'listWeek' : 'dayGridMonth');
		calendar.setOption('header', getHeaderProps());
	};

	return (
		<div className={rootClassName}>
			<Loading value={!loading}>
				<FullCalendar
					defaultView={window.innerWidth < 765 ? 'listWeek' : 'dayGridMonth'}
					header={getHeaderProps()}
					views={{
						listWeek: { buttonText: 'week' }
					}}
					contentHeight={1300}
					plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
					ref={calendarComponentRef}
					weekends={true}
					noEventsMessage="No entries to display."
					events={events}
					dateClick={handleDateClick}
					eventRender={handleEventRender}
					eventClick={handleEventClick}
					windowResize={handleWindowResize}
				/>
			</Loading>
		</div>
	);
};
