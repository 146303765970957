import { Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Header from 'components/nav/Header';
import Meta from 'elements/Meta';
import React from 'react';

type DashboardTemplateProps = {
	pageTitle: string;
	className?: string;
	children: any;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minHeight: '100vh',
		backgroundColor: '#f8f1e9'
		// position: "relative"
	},
	block: {
		backgroundColor: theme.palette.primary.main,
		width: '100%',
		height: 144,
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 0
	},
	container: {
		position: 'absolute',
		width: '100%',
		// height: "100%",
		marginTop: theme.spacing(4),
		top: 64,
		right: 0,
		left: 0,
		zIndex: 1
	}
}));

export default ({ pageTitle, className, children }: DashboardTemplateProps) => {
	const classes = useStyles();

	const containerClassname = clsx(classes.container, className);

	return (
		<div className={classes.root}>
			<Meta title={pageTitle} />

			<div className={classes.block} />

			<Header elevation={0} />

			<Container maxWidth="lg" component="main" className={containerClassname}>
				{children}
			</Container>
		</div>
	);
};
