import { Config } from "@pretty-green/common";
import { Dispatch } from "redux";
import configClient from "services/config";
import { ConfigType } from "store/types";

const GET_CONFIG_REQUEST = () => ({ type: ConfigType.GetConfigRequest });
const GET_CONFIG_SUCCESS = (config: Config) => ({
    type: ConfigType.GetConfigSuccess,
    data: config,
});
const GET_CONFIG_FAILURE = (error: any) => ({
    type: ConfigType.GetConfigFailure,
    data: error,
});

export default (dispatch: Dispatch, state: any) => ({
    getConfig: async (): Promise<void> => {
        try {
            console.log("Getting config");
            dispatch(GET_CONFIG_REQUEST());
            const config: Config = await configClient.getConfig();
            console.log(config);
            dispatch(GET_CONFIG_SUCCESS(config));
        } catch (error) {
            console.error(error);
            dispatch(GET_CONFIG_FAILURE(error));
        }
    },
});
