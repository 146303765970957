import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

type EntryCardProps = {
	className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 250,
		height: 250,
		border: `4px dashed #d1d1d1`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		color: '#d1d1d1',
		fontSize: '12em'
	}
}));

export default ({ className }: EntryCardProps) => {
	const classes = useStyles();
	const rootClassname = clsx(classes.root, className);

	return (
		<Link to="/entries/create" className={rootClassname}>
			<AddIcon className={classes.icon} />
		</Link>
	);
};
