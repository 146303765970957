import { EntryStatus } from "@pretty-green/common";
import { EntryStatusStrings } from "components/entries/EntryForm";
import React, { Fragment } from "react";

type EntryStatusProps = {
    value: EntryStatus;
};

export default ({ value }: EntryStatusProps) => {
    return <Fragment>{EntryStatusStrings[value]}</Fragment>;
};
