import { EventInput } from "@fullcalendar/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { Entry, Gift } from "@pretty-green/common";
import Calendar from "components/calendar/Calendar";
import EntryCard from "components/entries/EntryCard";
import EntryCardEmpty from "components/entries/EntryCardEmpty";
import Failure from "components/Failure";
import NoRecords from "components/NoRecords";
import { find } from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { Fragment, useEffect } from "react";
import { useStateContext, useStatus } from "store/configureStore";
import { LoadingStatus } from "store/reducers/status";
import { EntriesType } from "store/types";
import DashboardTemplate from "templates/DashboardTemplate";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: theme.spacing(3),
    },
    paper: {
        paddingBottom: theme.spacing(3),
    },
    toolbar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    title: {
        flex: "1 1 100%",
    },
    skeleton: {},
    noRecords: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    cards: {
        display: "flex",
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        overflow: "hidden",
        overflowX: "scroll",
    },
    card: {
        marginRight: 10,
    },
    calendar: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

export default () => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const [status, errors] = useStatus(EntriesType.GetEntries);
    const loading: boolean = status === LoadingStatus.Loading;
    const entries = state.entries.data;
    const gifts = state.gifts.data;

    useEffect(
        () => {
            actions.entries.getEntries({
                orderBy: "date",
                orderByDirection: "asc",
                ignoreExpired: true,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const events: EventInput[] = (entries || []).map((entry: Entry) => {
        const gift = find(gifts, (g: Gift) => g.id === entry.giftId);
        return {
            title: `${entry.forename} ${entry.surname}`,
            start: moment(entry.date).toDate(),
            allDay: true,
            id: entry.id,
            image: gift ? gift.image : null,
            content: numeral(entry.amount).format("$0,0"),
        };
    });

    return (
        <DashboardTemplate pageTitle="Schedule" className={classes.root}>
            <Failure value={errors}>
                <Paper className={classes.paper}>
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                        >
                            Up Next
                        </Typography>
                    </Toolbar>

                    <NoRecords
                        loading={loading}
                        records={entries}
                        message="No upcoming entries."
                        className={classes.noRecords}
                    />

                    <div className={classes.cards}>
                        {loading ? (
                            <Fragment>
                                <Skeleton
                                    variant="rect"
                                    width={250}
                                    height={250}
                                    className={classes.card}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={250}
                                    height={250}
                                    className={classes.card}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={250}
                                    height={250}
                                    className={classes.card}
                                />
                            </Fragment>
                        ) : entries && entries.length ? (
                            entries
                                .slice(0, 3)
                                .map((entry, key) => (
                                    <EntryCard
                                        key={key}
                                        entry={entry}
                                        className={classes.card}
                                    />
                                ))
                        ) : (
                            <EntryCardEmpty />
                        )}
                    </div>

                    {entries && entries.length ? (
                        <Fragment>
                            <Toolbar className={classes.toolbar}>
                                <Typography
                                    className={classes.title}
                                    variant="h6"
                                    id="tableTitle"
                                >
                                    Schedule
                                </Typography>
                            </Toolbar>
                            <Calendar
                                loading={loading}
                                events={events}
                                className={classes.calendar}
                            />
                        </Fragment>
                    ) : null}
                </Paper>
            </Failure>
        </DashboardTemplate>
    );
};
