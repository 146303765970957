import auth from './auth';
import config from './config';
import entries from './entries';
import gifts from './gifts';

export default (dispatch: any, state: any) => ({
    auth: auth(dispatch, state),
    config: config(dispatch, state),
    entries: entries(dispatch, state),
    gifts: gifts(dispatch, state)
});