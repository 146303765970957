import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
    },
}));

type DebugProps = {
    value: any;
};

const Debug = ({ value }: DebugProps) => {
    const classes = useStyles();

    return (
        <pre className={classes.root}>{ JSON.stringify(value, null, 2) }</pre>
    );
};

export default Debug;
