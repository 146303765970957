import { Gift } from "@pretty-green/common";
import { Dispatch } from "redux";
import giftsClient from "services/gifts";
import { GiftsType } from "store/types";

const GET_GIFTS_REQUEST = () => ({ type: GiftsType.GetGiftsRequest });
const GET_GIFTS_SUCCESS = (gifts: Gift[]) => ({
    type: GiftsType.GetGiftsSuccess,
    data: gifts,
});
const GET_GIFTS_FAILURE = (error: any) => ({
    type: GiftsType.GetGiftsFailure,
    data: error,
});

const GET_GIFT_REQUEST = () => ({ type: GiftsType.GetGiftRequest });
const GET_GIFT_SUCCESS = (gift: Gift) => ({
    type: GiftsType.GetGiftSuccess,
    data: gift,
});
const GET_GIFT_FAILURE = (error: any) => ({
    type: GiftsType.GetGiftFailure,
    data: error,
});

export default (dispatch: Dispatch, state: any) => ({
    getGifts: async (): Promise<void> => {
        try {
            console.log("Getting gits");
            dispatch(GET_GIFTS_REQUEST());
            const gifts: Gift[] = await giftsClient.getGifts();
            console.log("Got gifts", gifts);
            dispatch(GET_GIFTS_SUCCESS(gifts));
        } catch (error) {
            console.error(error);
            dispatch(GET_GIFTS_FAILURE(error));
        }
    },

    getGift: async (id: string): Promise<void> => {
        try {
            dispatch(GET_GIFT_REQUEST());
            const gift: Gift = await giftsClient.getGift(id);
            dispatch(GET_GIFT_SUCCESS(gift));
        } catch (error) {
            console.error(error);
            dispatch(GET_GIFT_FAILURE(error));
        }
    },
});
