import React, { Fragment } from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from "clsx";
import ErrorIcon from '@material-ui/icons/Error';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
// import WarningIcon from '@material-ui/icons/Warning';

type FailureProps = {
    value: string[],
    children?: any,
    className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        
    },
    toolbar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    title: {
        flex: '1 1 100%',
    },
    icon: {
        marginRight: theme.spacing(1)
    },
}));

export default ({
    value,
    children,
    className
}: FailureProps) => {
    const classes = useStyles();
    const rootClassname = clsx(classes.root, className);

    return value ? (
        <Paper>
            <div className={rootClassname}>    
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        Error
                    </Typography>
                </Toolbar>
                <div className={classes.content}>
                    <ErrorIcon className={classes.icon} />
                    <Typography>
                        Gosh, looks like something went wrong! Sorry about that - please try again later.
                    </Typography>
                </div>
            </div>
        </Paper>
    ): (
        <Fragment>        
            {children && children}
        </Fragment>
    );
};
