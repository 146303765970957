import { makeStyles, Theme } from "@material-ui/core";
import { User } from "@pretty-green/common";
import SunLolly from "assets/illustrations/characters/individual/png/Sun/PG-Character-Sun-Lolly.png";
import Button from "elements/Button";
import TextField from "elements/form/TextField";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStateContext, useStatus } from "store/configureStore";
import { LoadingStatus } from "store/reducers/status";
import { AuthType } from "store/types";
import SignupTemplate from "templates/SignupTemplate";
import * as Yup from "yup";

// import Debug from "elements/Debug";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    form: {},
    textField: {},
    formActions: {
        marginTop: theme.spacing(4),
    },
}));

interface FormValues {
    displayName: string;
    businessName?: string;
}

const SignupSchema = Yup.object().shape({
    displayName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    businessName: Yup.string(),
});

export default () => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const [status] = useStatus(AuthType.UpdateCurrentUser);
    // const [status, errors] = useStatus(AuthType.UpdateCurrentUser);
    const isWorking = status === LoadingStatus.Loading;
    const history = useHistory();

    const user: User = state.auth.user || ({} as User);
    let displayName: string = user && user.displayName ? user.displayName : "";
    let businessName: string =
        user && user.businessName ? user.businessName : "";

    const initialValues: FormValues = {
        displayName,
        businessName,
    };

    const handleSubmit = async (values: FormValues, formActions: any) => {
        // console.log({ values, formActions });
        formActions.setSubmitting(false);
        await actions.auth.updateCurrentUser(values);
        history.push("/signup/address");
    };

    return (
        <SignupTemplate
            pageTitle="Nice to meet you."
            image={SunLolly}
            imageAlt="Pretty Green"
            title="Nice to meet you."
            subtitle="Could you tell us your name and business name por favour?"
        >
            {/* <Debug value={state.status}/> */}
            <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
                render={(formikBag) => (
                    <Form noValidate>
                        <TextField
                            name="displayName"
                            label="Name"
                            placeholder="Name"
                            fullWidth
                            required
                            className={classes.textField}
                            disabled={isWorking}
                            margin="normal"
                        />
                        <TextField
                            name="businessName"
                            label="Business Name"
                            placeholder="Business Name"
                            fullWidth
                            className={classes.textField}
                            disabled={isWorking}
                            margin="normal"
                        />
                        <div className={classes.formActions}>
                            <Button
                                label="Continue"
                                type="submit"
                                working={isWorking}
                            />
                        </div>
                    </Form>
                )}
            />
        </SignupTemplate>
    );
};
