import {
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    InputLabel,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Gift } from "@pretty-green/common";
import clsx from "clsx";
import NoRecords from "components/NoRecords";
import { Field, FieldProps } from "formik";
import { find } from "lodash";
import React, { Fragment, useEffect } from "react";
import Popover from "@material-ui/core/Popover";

// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import Debug from "../Debug";

interface GiftListProps {
    name: string;
    margin?: string;
    className?: string;
    gifts: Gift[] | null;
    fullWidth?: Boolean;
    validate?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    inputLabel: {
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    gridList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        width: "100%",
    },
    title: {
        color: theme.palette.common.white,
    },
    gridListTile: {
        textAlign: "center",
        cursor: "pointer",
    },
    image: {
        height: "100%",
        width: "auto",
    },
    titleBar: {
        // `linear-gradient(to top, ${fade(theme.palette.secondary.main, 0.7)} 0%, ${fade(theme.palette.secondary.main, 0.3)} 70%, ${fade(theme.palette.secondary.main, 0)} 100%)`,
        background: theme.palette.secondary.main,
        "&.active": {
            background: theme.palette.primary.main,
        },
    },
    noRecords: {
        marginLeft: 0,
    },
    popover: {
        pointerEvents: "none",
    },
    popoverPaper: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        maxWidth: "40%",
    },
    popoverTitle: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
    },
    popoverDescription: {
        color: theme.palette.common.white,
    },
}));

export default (props: GiftListProps) => {
    const classes = useStyles();

    return (
        <Field {...props}>
            {({
                field, // { name, value, onChange, onBlur }
                form, // touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
            }: FieldProps) => {
                const { gifts } = props; // Strip out non-material-ui props
                const helpers = form.getFieldHelpers(field.name);
                const { setValue } = helpers;
                const { value } = meta;
                // console.log({ field, form, meta });

                const [anchorEl, setAnchorEl] = React.useState(null);
                const [
                    popoverDescription,
                    setPopoverDescription,
                ] = React.useState("");
                const [popoverTitle, setPopoverTitle] = React.useState("");

                const handlePopoverOpen = (event: any, gift: Gift) => {
                    setPopoverTitle(`${gift.title} ($${gift.amount})`);
                    setPopoverDescription(gift.description);
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                const handleClick = (
                    event: React.MouseEvent<unknown>,
                    gift: Gift
                ): void => {
                    setValue(gift.id);
                };

                useEffect(() => {
                    if (value && !find(gifts, { id: value })) {
                        setValue("");
                    }
                }, [gifts, setValue, value]);

                return (
                    <Fragment>
                        {/* <Debug value={value}/> */}
                        {/* <Debug value={{touched, errors, dirty, isValid, status}}/> */}
                        <div className={classes.root}>
                            <InputLabel className={classes.inputLabel}>
                                Gift List
                            </InputLabel>
                            <GridList className={classes.gridList} cols={5}>
                                {gifts &&
                                    gifts.map((gift) => (
                                        <GridListTile
                                            key={gift.id}
                                            onClick={(
                                                event: React.MouseEvent<unknown>
                                            ) => handleClick(event, gift)}
                                            onMouseEnter={(event) =>
                                                handlePopoverOpen(event, gift)
                                            }
                                            onMouseLeave={handlePopoverClose}
                                            className={classes.gridListTile}
                                        >
                                            <img
                                                src={gift.image}
                                                alt={gift.title}
                                                className={classes.image}
                                            />
                                            <GridListTileBar
                                                title={`${gift.title} ($${gift.amount})`}
                                                classes={{
                                                    root: clsx(
                                                        classes.titleBar,
                                                        {
                                                            active:
                                                                gift.id ===
                                                                value,
                                                        }
                                                    ),
                                                    title: classes.title,
                                                }}
                                                actionIcon={
                                                    <IconButton
                                                        aria-label={`star ${gift.title}`}
                                                    >
                                                        {/* <StarBorderIcon className={classes.title} /> */}
                                                    </IconButton>
                                                }
                                            />
                                        </GridListTile>
                                    ))}

                                <NoRecords
                                    loading={!gifts}
                                    records={gifts}
                                    message={
                                        "No gifts found matching the specified criteria."
                                    }
                                    className={classes.noRecords}
                                />
                            </GridList>
                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.popoverPaper,
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Typography className={classes.popoverTitle}>
                                    {popoverTitle}
                                </Typography>
                                <Typography
                                    className={classes.popoverDescription}
                                >
                                    {popoverDescription}
                                </Typography>
                            </Popover>
                        </div>
                    </Fragment>
                );
            }}
        </Field>
    );
};
