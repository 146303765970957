import {
    Entry,
    EntryCreationOptions,
    GetEntriesParams,
} from "@pretty-green/common";
import { Dispatch } from "redux";
import entriesClient from "services/entries";
import { EntriesType } from "store/types";

const GET_ENTRIES_REQUEST = () => ({ type: EntriesType.GetEntriesRequest });
const GET_ENTRIES_SUCCESS = (entries: Entry[]) => ({
    type: EntriesType.GetEntriesSuccess,
    data: entries,
});
const GET_ENTRIES_FAILURE = (error: any) => ({
    type: EntriesType.GetEntriesFailure,
    data: error,
});

const CREATE_ENTRY_REQUEST = () => ({ type: EntriesType.CreateEntryRequest });
const CREATE_ENTRY_SUCCESS = (entry: Entry) => ({
    type: EntriesType.CreateEntrySuccess,
    data: entry,
});
const CREATE_ENTRY_FAILURE = (error: any) => ({
    type: EntriesType.CreateEntryFailure,
    data: error,
});

const GET_ENTRY_REQUEST = () => ({ type: EntriesType.GetEntryRequest });
const GET_ENTRY_SUCCESS = (entry: Entry) => ({
    type: EntriesType.GetEntrySuccess,
    data: entry,
});
const GET_ENTRY_FAILURE = (error: any) => ({
    type: EntriesType.GetEntryFailure,
    data: error,
});

const UPDATE_ENTRY_REQUEST = () => ({ type: EntriesType.UpdateEntryRequest });
const UPDATE_ENTRY_SUCCESS = (entry: Entry) => ({
    type: EntriesType.UpdateEntrySuccess,
    data: entry,
});
const UPDATE_ENTRY_FAILURE = (error: any) => ({
    type: EntriesType.UpdateEntryFailure,
    data: error,
});

const RESET_ENTRY = () => ({ type: EntriesType.ResetEntry });

const DELETE_ENTRIES_REQUEST = () => ({
    type: EntriesType.DeleteEntriesRequest,
});
const DELETE_ENTRIES_SUCCESS = (ids: string[]) => ({
    type: EntriesType.DeleteEntriesSuccess,
    data: ids,
});
const DELETE_ENTRIES_FAILURE = (error: any) => ({
    type: EntriesType.DeleteEntriesFailure,
    data: error,
});

export default (dispatch: Dispatch, state: any) => ({
    getEntries: async (params?: GetEntriesParams): Promise<void> => {
        try {
            dispatch(GET_ENTRIES_REQUEST());
            const entries: Entry[] = await entriesClient.getEntries(params);
            dispatch(GET_ENTRIES_SUCCESS(entries));
        } catch (error) {
            console.error(error);
            dispatch(GET_ENTRIES_FAILURE(error));
        }
    },

    createEntry: async (attributes: EntryCreationOptions): Promise<void> => {
        try {
            dispatch(CREATE_ENTRY_REQUEST());
            const entry: Entry = await entriesClient.createEntry(attributes);
            dispatch(CREATE_ENTRY_SUCCESS(entry));
        } catch (error) {
            console.error(error);
            dispatch(CREATE_ENTRY_FAILURE(error));
        }
    },

    getEntry: async (id: string): Promise<void> => {
        try {
            dispatch(GET_ENTRY_REQUEST());
            const entry: Entry = await entriesClient.getEntry(id);
            dispatch(GET_ENTRY_SUCCESS(entry));
        } catch (error) {
            console.error(error);
            dispatch(GET_ENTRY_FAILURE(error));
        }
    },

    updateEntry: async (
        id: string,
        attributes: Partial<Entry>
    ): Promise<void> => {
        try {
            dispatch(UPDATE_ENTRY_REQUEST());
            const entry: Entry = await entriesClient.updateEntry(
                id,
                attributes
            );
            dispatch(UPDATE_ENTRY_SUCCESS(entry));
        } catch (error) {
            console.error(error);
            dispatch(UPDATE_ENTRY_FAILURE(error));
        }
    },

    resetSelectedEntry: async (): Promise<void> => {
        dispatch(RESET_ENTRY());
    },

    deleteEntries: async (ids: string[]): Promise<void> => {
        try {
            dispatch(DELETE_ENTRIES_REQUEST());
            await entriesClient.deleteEntries(ids);
            dispatch(DELETE_ENTRIES_SUCCESS(ids));
        } catch (error) {
            console.error(error);
            dispatch(DELETE_ENTRIES_FAILURE(error));
        }
    },
});
