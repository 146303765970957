import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from 'elements/Button';
import React from 'react';

// import { makeStyles, Theme } from '@material-ui/core/styles';
type ConfirmDialogProps = {
	title?: string;
	message?: string;
	isOpen: boolean;
	isWorking?: boolean;
	onClose: () => void;
	onConfirm: (event: React.MouseEvent<unknown>) => void;
};

export enum EntryDialogMode {
	Create,
	Edit
	// View,
}

// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
// }));

export default ({
	title = 'Confirm',
	message = 'Are you sure?',
	isOpen = false,
	isWorking = false,
	onClose,
	onConfirm
}: ConfirmDialogProps) => {
	// const classes = useStyles();

	return (
		<Dialog
			aria-labelledby="confirm-dialog"
			open={isOpen}
			onClose={onClose}
			// fullWidth={true}
			// maxWidth={'lg'}
			// disableBackdropClick
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={onClose}>
					Cancel
				</Button>
				<Button type="submit" form="entryForm" working={isWorking} onClick={onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
