import React from "react";
import { makeStyles, Theme, Fab, CircularProgress } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';

export type ButtonProps = {
    color?: "inherit" | "default" | "primary" | "secondary" | undefined;
    label?: string;
    ariaLabel?: string;
    icon?: string;
    disabled?: boolean;
    working?: boolean;
    success?: boolean;
    className?: string;
    onClick?: any;
    type?: "button" | "reset" | "submit" | undefined;
    children?: any;
    [key: string]: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    button: {
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    icon: {
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
            width: 25,
        },
    },
    iconWorking: {
        opacity: .5
    },
    iconDisabled: {
        opacity: .5
    },
    iconSuccess: {}
}));

const Button = ({
    color = 'secondary',
    label,
    ariaLabel = "",
    icon,
    disabled = false,
    working = false,
    success = false,
    className,
    onClick,
    type,
    children,
    ...props
}: ButtonProps) => {
    const classes = useStyles();

    const buttonClassname = clsx(classes.button, className, {
        [classes.buttonSuccess]: success,
    });

    const iconClassname = clsx(classes.icon, {
        [classes.iconDisabled]: disabled,
        [classes.iconWorking]: working,
        [classes.iconSuccess]: success,
    });

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Fab
                    variant="extended"
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled || working}
                    className={buttonClassname}
                    onClick={onClick}
                    type={type}
                    disableFocusRipple
                    {...props}
                >
                    {working && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
                    {icon && <img src={icon} alt={label} className={iconClassname} />}
                    {label && label}
                    {children && children}
                </Fab>
            </div>
        </div>
    );
};

export default Button;
