import { makeStyles, Theme } from "@material-ui/core";
import { Config } from "@pretty-green/common";
import icon from "assets/images/facebook-icon.png";
import Button from "elements/Button";
import React, { Fragment } from "react";
import Login from "react-facebook-login/dist/facebook-login-render-props";
import { useStateContext } from "store/configureStore";

// import Debug from 'elements/Debug';
const useStyles = makeStyles((theme: Theme) => ({
    button: {
        marginTop: 60,
    },
}));

export default (props: any) => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const config: Config | null = state.config;

    const handleResponse = (response: any) => {
        console.log(response);
        actions.auth.login(response.accessToken);
    };

    return (
        config && (
            <Login
                appId={config.facebook.appId}
                autoLoad={false}
                fields="name,email,picture"
                // onClick={componentClicked}
                callback={handleResponse}
                disableMobileRedirect={true}
                render={(props: any) => (
                    <Fragment>
                        {/* <Debug value={props} /> */}
                        <Button
                            label="Facebook Login"
                            icon={icon}
                            disabled={props.isDisabled}
                            working={props.isProcessing}
                            className={classes.button}
                            onClick={props.onClick}
                        />
                    </Fragment>
                )}
            />
        )
    );
};
