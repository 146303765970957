import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Fab from "@material-ui/core/Fab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { Entry, Gift } from "@pretty-green/common";
import clsx from "clsx";
import { find } from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "store/configureStore";

type EntryCardProps = {
    className?: string;
    entry: Entry;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 200,
        maxWidth: 300,
    },
    header: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    edit: {
        marginLeft: "auto",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default ({ className, entry }: EntryCardProps) => {
    const classes = useStyles();
    const { state } = useStateContext();
    const rootClassname = clsx(classes.root, className);
    const gifts: Gift[] | null = state.gifts.data;
    const gift: Gift | null | undefined =
        entry.giftId && gifts
            ? find(gifts, (g: Gift) => g.id === entry.giftId)
            : null;

    return (
        <Card className={rootClassname} variant="outlined">
            <CardHeader
                title={`${entry.forename} ${entry.surname}`}
                subheader={moment(entry.date).format("ddd, Do MMM YYYY")}
                subheaderTypographyProps={{ color: "inherit" }}
                className={classes.header}
            />
            {gift && (
                <CardMedia
                    className={classes.media}
                    image={gift.image}
                    title={gift.title}
                />
            )}
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {gift ? gift.description : "No gift selected"}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Fab
                    aria-label="edit"
                    className={classes.edit}
                    to={`entries/${entry.id}`}
                    component={Link}
                >
                    <EditIcon />
                </Fab>
            </CardActions>
        </Card>
    );
};
