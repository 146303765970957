import { makeStyles, Theme } from '@material-ui/core';
import Loading from 'components/Loading';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useStateContext } from 'store/configureStore';
import SignupTemplate from 'templates/SignupTemplate';

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	loading: {
		marginTop: theme.spacing(4)
	}
}));

export default () => {
	const classes = useStyles();
	const { actions } = useStateContext();
	const history = useHistory();

	useEffect(
		() => {
			const logout = async (): Promise<void> => {
				await actions.auth.logout();
				history.push('/');
			};
			logout();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SignupTemplate pageTitle="Facebook Login" title="Logging out" subtitle="See you next time!">
			<Loading value={false} className={classes.loading} />
		</SignupTemplate>
	);
};
