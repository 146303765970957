import React, { Fragment } from "react";
import { Field, FieldProps } from 'formik';
// import Debug from "../Debug";
import { KeyboardDatePicker } from '@material-ui/pickers';

// type DateFieldProps = {

// }

export default (props: any) => {
    return (
        <Field {...props}>
            {({
                field, // { name, value, onChange, onBlur }
                form, // touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
                // meta,
            }: FieldProps) => {
                const { validate, ...rest } = props; // Strip out invalid TextField props
                return (
                    <Fragment>
                        {/* <Debug value={{field}}/> */}
                        <KeyboardDatePicker
                            // {...field}
                            {...rest}
                            name={field.name}
                            value={field.value}
                            onChange={value => {
                                form.setFieldValue("date", value);
                            }}
                            onBlur={field.onBlur}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            animateYearScrolling={false}
                        />
                        {/* <TextField
                            {...field}
                            {...rest}
                            error={!!(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error && meta.error}
                        /> */}
                    </Fragment>
                );
            }}
          </Field>
    );
}
                                