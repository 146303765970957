export enum ConfigType {
    GetConfig = 'Config:GetConfig', // only used for status
    GetConfigRequest = 'Config:GetConfigRequest',
    GetConfigSuccess = 'Config:GetConfigSuccess',
    GetConfigFailure = 'Config:GetConfigFailure',
}

export enum HealthType {
    CheckHealth = 'Health:CheckHealth',  // only used for status
    CheckHealthRequest = 'Health:CheckHealthRequest',
    CheckHealthSuccess = 'Health:CheckHealthSuccess',
    CheckHealthFailure = 'Health:CheckHealthFailure'
}

export enum AuthType {
    Login = 'Auth:Login', // only used for status
    LoginRequest = 'Auth:LoginRequest',
    LoginSuccess = 'Auth:LoginSuccess',
    LoginFailure = 'Auth:LoginFailure',

    Logout = 'Auth:Logout', // only used for status
    LogoutRequest = 'Auth:LogoutRequest',
    LogoutSuccess = 'Auth:LogoutSuccess',
    LogoutFailure = 'Auth:LogoutFailure',

    GetCurrentUser = 'Auth:GetCurrentUser', // only used for status
    GetCurrentUserRequest = 'Auth:GetCurrentUserRequest',
    GetCurrentUserSuccess = 'Auth:GetCurrentUserSuccess',
    GetCurrentUserFailure = 'Auth:GetCurrentUserFailure',
    
    UpdateCurrentUser = 'Auth:UpdateCurrentUser', // only used for status
    UpdateCurrentUserRequest = 'Auth:UpdateCurrentUserRequest',
    UpdateCurrentUserSuccess = 'Auth:UpdateCurrentUserSuccess',
    UpdateCurrentUserFailure = 'Auth:UpdateCurrentUserFailure',

    AddPayment = 'Auth:AddPayment', // only used for status
    AddPaymentRequest = 'Auth:AddPaymentRequest',
    AddPaymentSuccess = 'Auth:AddPaymentSuccess',
    AddPaymentFailure = 'Auth:AddPaymentFailure',
}

export enum EntriesType {
    GetEntries = 'Entries:GetEntries',  // only used for status
    GetEntriesRequest = 'Entries:GetEntriesRequest',
    GetEntriesSuccess = 'Entries:GetEntriesSuccess',
    GetEntriesFailure = 'Entries:GetEntriesFailure',
    
    CreateEntry = 'Entries:CreateEntry',  // only used for status
    CreateEntryRequest = 'Entries:CreateEntryRequest',
    CreateEntrySuccess = 'Entries:CreateEntrySuccess',
    CreateEntryFailure = 'Entries:CreateEntryFailure',

    GetEntry = 'Entries:GetEntry',  // only used for status
    GetEntryRequest = 'Entries:GetEntryRequest',
    GetEntrySuccess = 'Entries:GetEntrySuccess',
    GetEntryFailure = 'Entries:GetEntryFailure',

    UpdateEntry = 'Entries:UpdateEntry',  // only used for status
    UpdateEntryRequest = 'Entries:UpdateEntryRequest',
    UpdateEntrySuccess = 'Entries:UpdateEntrySuccess',
    UpdateEntryFailure = 'Entries:UpdateEntryFailure',

    ResetEntry = 'Entries:ResetEntry',

    DeleteEntries = 'Entries:DeleteEntries',  // only used for status
    DeleteEntriesRequest = 'Entries:DeleteEntriesRequest',
    DeleteEntriesSuccess = 'Entries:DeleteEntriesSuccess',
    DeleteEntriesFailure = 'Entries:DeleteEntriesFailure',
}

export enum GiftsType {
    GetGifts = 'Gifts:GetGifts',  // only used for status
    GetGiftsRequest = 'Gifts:GetGiftsRequest',
    GetGiftsSuccess = 'Gifts:GetGiftsSuccess',
    GetGiftsFailure = 'Gifts:GetGiftsFailure',
    
    GetGift = 'Gifts:GetGift',  // only used for status
    GetGiftRequest = 'Gifts:GetGiftRequest',
    GetGiftSuccess = 'Gifts:GetGiftSuccess',
    GetGiftFailure = 'Gifts:GetGiftFailure',
}