import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import clsx from "clsx";

type FormRowProps = {
    className?: string;
    columns?: number;
    children: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    split: {
        '& > $div': {
            flexBasis: '50%',
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        },
        '& > $div:first-child': {
            marginLeft: 0
        },
        '& > $div:last-child': {
            marginRight: 0
        },
    }
}));

export default ({
    className,
    columns,
    children
}: FormRowProps) => {
    const classes = useStyles();
    const rootClassname = clsx(classes.root, className, {
        [classes.split]: !columns || columns > 1
    });

    return (
        <div className={rootClassname}>
            {children}
        </div>
    );
};
