import { Typography } from '@material-ui/core';
import PrettyGreenLogomark from 'assets/logos/PG-Logomark-Primary.svg';
import FacebookLogin from 'components/FacebookLogin';
import React from 'react';
import SignupTemplate from 'templates/SignupTemplate';

// import { Link as RouterLink } from "react-router-dom";
// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
//     link: {},
//     logomark: {
//         maxWidth: 300,
//         height: 'auto'
//     },
//     footer: {
//         color: theme.palette.grey[800],
//         marginTop: theme.spacing(2)
//     }
// }));

export default () => {
	// const classes = useStyles();

	return (
		<SignupTemplate
			pageTitle="Facebook Login"
			image={PrettyGreenLogomark}
			imageAlt="Pretty Green"
			imageSize="large"
		>
			<Typography>Login with your Facebook account to get started.</Typography>

			<FacebookLogin />

			{/* <Typography variant="body2" className={classes.footer}>
                <span>
                    Not part of the gang yet?
                </span>{" "}
                <Link component={RouterLink} to="/signup">
                    Get started here
                </Link>
            </Typography> */}
		</SignupTemplate>
	);
};
