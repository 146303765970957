import { ConfigType } from 'store/types';

import initialState from '../initialState';

export default (state = initialState.config, action: any) => {
	switch (action.type) {
		case ConfigType.GetConfigRequest:
			return null;

		case ConfigType.GetConfigSuccess:
			return action.data;

		default:
			return state;
	}
};
