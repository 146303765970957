import { createMuiTheme } from '@material-ui/core';

const Regular = ["'Metric-Web-Regular'", 'sans-serif'].join(',');
const Medium = ["'Metric-Web-Medium'", 'sans-serif'].join(',');
const Black = ["'Metric-Web-Black'", 'sans-serif'].join(',');

export default createMuiTheme({
	typography: {
		fontFamily: Regular,
		fontSize: 16,
		h1: {
			fontFamily: Black
		},
		h2: {
			fontFamily: Black
		},
		h3: {
			fontFamily: Black,
			fontSize: '2rem'
		},
		h4: {
			fontFamily: Medium,
			fontSize: '1.5rem'
		},
		h6: {
			fontFamily: Black
		}
	},
	palette: {
		primary: {
			main: '#263045'
			// light: will be calculated from palette.primary.main,
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			main: '#f4bd37'
		}
	},
	overrides: {
		MuiLink: {
			root: {
				fontWeight: 'bold',
				color: '#f4bd37'
			}
		},
		MuiFab: {
			root: {
				fontWeight: 'bold',
				fontSize: '1.5rem',
				textTransform: 'none'
			}
		},
		MuiTableCell: {
			head: {
				fontWeight: 'bold'
			}
		}
	}
	// overrides: {
	//     MuiCssBaseline: {
	//         "@global": {
	//             "@font-face": [rooney]
	//         }
	//     }
	// }
});
