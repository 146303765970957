export enum LoadingStatus {
	Loading,
	Failure,
	Success
}

const getStatus = (requestState: string) => {
	switch (requestState) {
		case 'Request':
			return LoadingStatus.Loading;
		case 'Failure':
			return LoadingStatus.Failure;
		case 'Success':
			return LoadingStatus.Success;
	}
};

const status = (state = {}, action: any) => {
	const { type } = action;
	const matches = /(.*)(Request|Success|Failure)/.exec(type);

	// Not a relevant action, so we ignore it
	if (!matches) {
		return state;
	}

	const [, requestName, requestState] = matches;
	// console.log('store/reducers/loading.ts', requestName, requestState);
	return {
		...state,
		// Store whether a request is happening at the moment or not
		// e.g. will be true when receiving GET_TODOS_REQUEST
		//      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
		[requestName]: getStatus(requestState)
	};
};

export default status;
