import { EntryDietary } from "@pretty-green/common";
import { EntryDietaryStrings } from "components/entries/EntryForm";
import React, { Fragment } from "react";

type EntryDietariesProps = {
    value: EntryDietary[];
};

export default ({ value }: EntryDietariesProps) => {
    return (
        <Fragment>
            {value
                .map((dietary: string) => EntryDietaryStrings[dietary])
                .join(", ")}
        </Fragment>
    );
};
