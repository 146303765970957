import { makeStyles, Theme } from "@material-ui/core/styles";
import MUITextField from "@material-ui/core/TextField";
import {
    Entry,
    EntryDietary,
    EntryOccasion,
    EntryStatus,
    Gift,
} from "@pretty-green/common";
import Checkbox from "elements/form/Checkbox";
import DateField from "elements/form/DateField";
import FormRow from "elements/form/FormRow";
import GiftList from "elements/form/GiftList";
import Select from "elements/form/Select";
import TextField from "elements/form/TextField";
import { Form } from "formik";
import { filter, indexOf, intersection } from "lodash";
import React, { Fragment } from "react";
import { optionsFromStrings } from "utils/strings";

import { EntryDialogMode } from "./EntryDialog";

// import Debug from "elements/Debug";

type EntryFormProps = {
    mode: EntryDialogMode;
    formikBag: any;
    gifts: Gift[] | null;
    entry: Entry | null;
    readonly: boolean;
    working: boolean;
};

export const EntryStatusStrings = {
    [EntryStatus.Active]: "Active",
    [EntryStatus.Inactive]: "Inactive",
    [EntryStatus.Dispatched]: "Dispatched",
};

export const EntryOccasionStrings: any = {
    [EntryOccasion.Birthday]: "Birthday",
    [EntryOccasion.Anniversary]: "Anniversary",
    [EntryOccasion.NewYearsGreeting]: "New Years Greeting",
    [EntryOccasion.MerryChristmas]: "Merry Christmas",
    [EntryOccasion.ValentinesDay]: "Valentines Day",
    [EntryOccasion.AustraliaDay]: "Australia Day",
    [EntryOccasion.HappyEaster]: "Happy Easter",
    [EntryOccasion.NewBaby]: "New Baby",
    [EntryOccasion.ThankYou]: "Thank You",
    [EntryOccasion.Promotion]: "Promotion",
    [EntryOccasion.FirstDayAtWork]: "First Day At Work",
};

export const EntryDietaryStrings: any = {
    [EntryDietary.Vegan]: "Vegan",
    [EntryDietary.Vegetarian]: "Vegetarian",
    [EntryDietary.Celiac]: "Celiac (Gluten Free)",
    [EntryDietary.LactoseIntolerant]: "Lactose Intolerant (Dairy Free)",
    [EntryDietary.NoAlcohol]: "No Alcohol",
    [EntryDietary.Diabetic]: "Diabetic",
};

const occasions = optionsFromStrings(EntryOccasionStrings);

const dietaries = optionsFromStrings(EntryDietaryStrings);

const amounts = [
    {
        key: "Under $50",
        value: 50,
    },
    {
        key: "Under $100",
        value: 100,
    },
    {
        key: "Under $150",
        value: 150,
    },
    {
        key: "Under $200",
        value: 200,
    },
    {
        key: "Under $250",
        value: 250,
    },
    {
        key: "Under $300",
        value: 300,
    },
];

// const getDietariesLabel = (option: any) => option.value;

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    row: {},
    textField: {},
    select: {},
    giftList: {},
    checkbox: {},
    formActions: {
        marginTop: theme.spacing(4),
    },
}));

export default ({
    mode,
    formikBag,
    gifts,
    entry,
    readonly = false,
    working = false,
}: EntryFormProps) => {
    const classes = useStyles();

    const filteredGifts = (): Gift[] | null => {
        const {
            values: { occasion, dietaries, amount },
        } = formikBag;

        // console.log({ occasion, dietaries, amount, gifts });

        return filter(
            gifts,
            (gift: Gift) =>
                gift.amount <= amount &&
                (!occasion || indexOf(gift.occasion, occasion) !== -1) &&
                (dietaries.length === 0 ||
                    intersection(dietaries, gift.dietaries).length > 0)
        );
    };

    return (
        <Fragment>
            {/* <Debug value={formikBag.values} /> */}
            <Form id="entryForm" noValidate>
                {mode === EntryDialogMode.Edit && entry && (
                    <FormRow className={classes.row}>
                        <MUITextField
                            name="status"
                            label="Status"
                            value={EntryStatusStrings[entry.status]}
                            fullWidth
                            className={classes.textField}
                            disabled={true}
                            margin="normal"
                        />
                    </FormRow>
                )}
                <FormRow className={classes.row}>
                    <TextField
                        name="forename"
                        label="Forename"
                        placeholder="Forename"
                        fullWidth
                        required
                        className={classes.textField}
                        disabled={readonly || working}
                        margin="normal"
                    />
                    <TextField
                        name="surname"
                        label="Surname"
                        placeholder="Surname"
                        fullWidth
                        required
                        className={classes.textField}
                        disabled={readonly || working}
                        margin="normal"
                    />
                </FormRow>
                <FormRow className={classes.row}>
                    <Select
                        name="occasion"
                        label="Occasion"
                        placeholder="Occasion"
                        fullWidth
                        required
                        className={classes.select}
                        disabled={readonly || working}
                        options={occasions}
                        margin="normal"
                    />
                    <Select
                        name="dietaries"
                        label="Dietaries"
                        placeholder="Dietaries"
                        fullWidth
                        multiple
                        className={classes.select}
                        disabled={readonly || working}
                        options={dietaries}
                        margin="normal"
                    />
                </FormRow>
                <FormRow className={classes.row}>
                    <DateField
                        name="date"
                        label="Date"
                        placeholder="Date"
                        fullWidth
                        required
                        className={classes.textField}
                        disabled={readonly || working}
                        margin="normal"
                    />
                    <Select
                        name="amount"
                        label="Amount"
                        placeholder="Amount"
                        fullWidth
                        required
                        className={classes.select}
                        disabled={readonly || working}
                        options={amounts}
                        margin="normal"
                    />
                </FormRow>
                <TextField
                    name="comments"
                    label="Comments"
                    placeholder="Comments"
                    fullWidth
                    className={classes.textField}
                    disabled={readonly || working}
                    margin="normal"
                />
                <FormRow className={classes.row} columns={1}>
                    <GiftList
                        name="giftId"
                        margin="normal"
                        className={classes.giftList}
                        gifts={filteredGifts()}
                        fullWidth
                    />
                </FormRow>
                <FormRow className={classes.row}>
                    <Checkbox
                        name="sameAsMyAddress"
                        label="Same as my address"
                        placeholder="Same as my address"
                        className={classes.checkbox}
                        disabled={readonly || working}
                        margin="normal"
                    />
                </FormRow>
                {!formikBag.values.sameAsMyAddress && (
                    <Fragment>
                        <TextField
                            name="line1"
                            label="Address Line 1"
                            placeholder="Address Line 1"
                            required
                            className={classes.textField}
                            disabled={readonly || working}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="line2"
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            className={classes.textField}
                            disabled={readonly || working}
                            fullWidth
                            margin="normal"
                        />
                        <FormRow className={classes.row}>
                            <TextField
                                name="city"
                                label="City"
                                placeholder="City"
                                required
                                className={classes.textField}
                                disabled={readonly || working}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                name="postcode"
                                label="Postcode"
                                placeholder="Postcode"
                                required
                                className={classes.textField}
                                disabled={readonly || working}
                                fullWidth
                                margin="normal"
                            />
                        </FormRow>
                        <FormRow className={classes.row}>
                            <TextField
                                name="state"
                                label="State"
                                placeholder="State"
                                required
                                className={classes.textField}
                                disabled={readonly || working}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                name="country"
                                label="Country"
                                placeholder="Country"
                                required
                                className={classes.textField}
                                disabled={readonly || working}
                                fullWidth
                                margin="normal"
                            />
                        </FormRow>
                    </Fragment>
                )}
            </Form>
        </Fragment>
    );
};
