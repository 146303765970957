import { Checkbox, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import { Address, Entry, User } from "@pretty-green/common";
import NoRecords from "components/NoRecords";
import Currency from "elements/filters/Currency";
import Date from "elements/filters/Date";
import EntryDietaries from "elements/filters/EntryDietaries";
import EntryOccasion from "elements/filters/EntryOccasion";
import EntryStatus from "elements/filters/EntryStatus";
import React, { Fragment } from "react";
import { useStateContext } from "store/configureStore";

type EntryTableProps = {
    entries: Entry[] | null;
    loading: boolean;
    selected: string[];
    onClick?: (event: React.MouseEvent<unknown>, entry: Entry) => any;
    onSelect?: (event: React.MouseEvent<unknown>, ids: string[]) => any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    row: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    skeleton: {
        padding: theme.spacing(1),
    },
    skeletonLine: {
        backgroundColor: theme.palette.secondary.main,
    },
    noRecords: {
        padding: theme.spacing(3),
    },
    hideMobile: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
}));

export default ({
    entries,
    loading,
    selected,
    onClick,
    onSelect,
}: EntryTableProps) => {
    const classes = useStyles();
    const { state } = useStateContext();

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const getCity = (entry: Entry): string => {
        const user = state.auth.user || ({} as User);
        const address = user.address || ({} as Address);
        return entry.sameAsMyAddress ? address.city : entry.city;
    };

    const handleClickCheckbox = (
        event: React.MouseEvent<unknown>,
        id: string
    ) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (onSelect) {
            onSelect(event, newSelected);
        }
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        entry: Entry
    ): void => {
        if (onClick) {
            onClick(event, entry);
        }
    };

    return (
        <Fragment>
            <Table className={classes.root} aria-label="Entry table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.hideMobile} />
                        <TableCell className={classes.hideMobile}>
                            Status
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell className={classes.hideMobile}>
                            Occasion
                        </TableCell>
                        <TableCell className={classes.hideMobile}>
                            Dietaries
                        </TableCell>
                        <TableCell className={classes.hideMobile}>
                            Date
                        </TableCell>
                        <TableCell align="right" className={classes.hideMobile}>
                            Value
                        </TableCell>
                        <TableCell className={classes.hideMobile}>
                            City
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entries &&
                        entries.map((entry: Entry, index: number) => (
                            <TableRow
                                hover
                                key={entry.id}
                                className={classes.row}
                            >
                                <TableCell
                                    padding="checkbox"
                                    className={classes.hideMobile}
                                >
                                    <Checkbox
                                        checked={isSelected(entry.id)}
                                        inputProps={{
                                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                        }}
                                        onClick={(
                                            event: React.MouseEvent<unknown>
                                        ) =>
                                            handleClickCheckbox(event, entry.id)
                                        }
                                    />
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    className={classes.hideMobile}
                                >
                                    <EntryStatus value={entry.status} />
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                >
                                    {entry.forename} {entry.surname}
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    className={classes.hideMobile}
                                >
                                    <EntryOccasion value={entry.occasion} />
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    className={classes.hideMobile}
                                >
                                    <EntryDietaries value={entry.dietaries} />
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    className={classes.hideMobile}
                                >
                                    <Date
                                        value={entry.date}
                                        format="DD/MM/YY"
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    align="right"
                                    className={classes.hideMobile}
                                >
                                    <Currency
                                        value={entry.amount}
                                        format="$0,0"
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={(
                                        event: React.MouseEvent<unknown>
                                    ) => handleClick(event, entry)}
                                    className={classes.hideMobile}
                                >
                                    {getCity(entry)}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            {loading && (
                <div className={classes.skeleton}>
                    <Skeleton className={classes.skeletonLine} />
                    <Skeleton className={classes.skeletonLine} />
                    <Skeleton className={classes.skeletonLine} />
                </div>
            )}

            <NoRecords
                loading={loading}
                records={entries}
                message="No entries found. "
                className={classes.noRecords}
            />
            {/* <Link to="/entries/create">Create your first one.</Link> */}
        </Fragment>
    );
};
