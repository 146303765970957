import { GiftsType } from "store/types";

import initialState from "../initialState";

// import { Gift } from '@pretty-green/common';

export default (state = initialState.gifts, action: any) => {
    switch (action.type) {
        case GiftsType.GetGiftsRequest:
            return { ...state, data: null };

        case GiftsType.GetGiftsSuccess:
            return { ...state, data: action.data };

        // case GiftsType.GetGiftSuccess:
        //     return { ...state, selectedGift: action.data };

        default:
            return state;
    }
};
