import "./App.css";

import MomentUtils from "@date-io/moment";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import Loading from "./components/Loading";
import Unhealthy from "./components/Unhealthy";
import Routes from "./Routes";
import { checkHealth } from "./services/health";
import { useStateContext, useStatus } from "./store/configureStore";
import { LoadingStatus } from "./store/reducers/status";
import { ConfigType } from "./store/types";
import theme from "./theme";

// import Debug from "./elements/Debug";
const App: React.FC = () => {
    const { state, actions } = useStateContext();
    const { isAuthenticating, isAuthenticated } = state.auth;
    const [configStatus] = useStatus(ConfigType.GetConfig);
    // const [status, errors] = useStatus(HealthType.CheckHealth);
    const [isHealthy, setIsHealthy] = useState(true);

    const isLoading =
        isAuthenticating || configStatus === LoadingStatus.Loading;

    // Perform a health check on initial load and get config and current user
    useEffect(
        () => {
            const healthCheck = async () => {
                try {
                    await checkHealth();
                } catch (error) {
                    setIsHealthy(false);
                }
            };
            healthCheck();
            actions.config.getConfig();
            actions.auth.getCurrentUser();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // Get the gifts
    useEffect(
        () => {
            // @todo should this exist elsewhere?
            // @todo should we prevent the app from loading until this is complete?
            if (isAuthenticated) {
                actions.gifts.getGifts();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAuthenticated]
    );

    return (
        <div className="App">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {/* <Debug value={{ isAuthenticating, errors }}/> */}
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <Loading value={!isLoading}>
                        {isHealthy ? <Routes /> : <Unhealthy />}
                    </Loading>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default App;
