import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Redirect } from 'react-router';
import { useStateContext } from 'store/configureStore';
import VerticalTemplate from 'templates/VerticalTemplate';

// import Debug from "elements/Debug";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		// height: '100vh',
		// justifyContent: 'center'
	}
}));

export default () => {
	const classes = useStyles();
	const { state } = useStateContext();

	const isAuthenticated = state.auth.isAuthenticated;
	const user = state.auth.user || {
		isOnboarded: false
	};
	const isOnboarded = user.isOnboarded;

	// if isAuthenticated && isOnboarded then redirect to entries
	// if isAuthenticated && !isOnboarded then redirect to signup
	// else redirect to login

	return (
		// <Debug value={{isAuthenticated, isOnboarded}}/>
		<VerticalTemplate className={classes.root}>
			{isAuthenticated && isOnboarded && <Redirect to="/entries" />}
			{isAuthenticated && !isOnboarded && <Redirect to="/signup" />}
			{!isAuthenticated && <Redirect to="/login" />}
		</VerticalTemplate>
	);
};
