import { User } from "@pretty-green/common";
import apiClient from "utils/api";

export default {
    login: async (accessToken: string): Promise<any> => {
        const { data } = await apiClient.post("/auth/facebook", {
            accessToken,
        });
        return data;
    },

    logout: async (): Promise<any> => {
        const { data } = await apiClient.post("/auth/logout");
        return data;
    },

    getCurrentUser: async (): Promise<User> => {
        const { data }: { data: User } = await apiClient.get("/auth/profile");
        return data;
    },

    updateCurrentUser: async (attributes: Partial<User>): Promise<User> => {
        const { data }: { data: User } = await apiClient.put(
            "/auth/profile",
            attributes
        );
        return data;
    },

    addPayment: async (token: any): Promise<any> => {
        const { data }: { data: User } = await apiClient.put("/auth/payment", {
            token,
        });
        return data;
    },
};
