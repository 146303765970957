import React from "react";
import { Helmet } from "react-helmet";

type MetaProps = {
    title: string;
    description?: string;
};

const Meta = ({ title, description }: MetaProps) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default Meta;
