import { EntryOccasion } from "@pretty-green/common";
import { EntryOccasionStrings } from "components/entries/EntryForm";
import React, { Fragment } from "react";

type EntryOccasionProps = {
    value: EntryOccasion;
};

export default ({ value }: EntryOccasionProps) => {
    return <Fragment>{EntryOccasionStrings[value]}</Fragment>;
};
