import React, { Fragment } from 'react';
import numeral from 'numeral';

type CurrencyProps = {
    value: number,
    format?: string
}

export default ({
    value,
    format = '$0,0.00'
}: CurrencyProps) => {
    return (<Fragment>{numeral(value).format(format)}</Fragment>);
};