import { Container, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Header from 'components/nav/Header';
import Meta from 'elements/Meta';
import React from 'react';

export type SignupTemplateProps = {
	pageTitle: string;
	image?: string;
	imageSize?: 'small' | 'medium' | 'large';
	imageAlt?: string;
	title?: string;
	subtitle?: string;
	children?: any;
	footer?: any;
	useHeader?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100vh',
		textAlign: 'center',
		backgroundColor: '#f8f1e9'
	},
	content: {
		position: 'relative'
	},
	background: {
		display: 'flex',
		flexDirection: 'column'
	},
	backgroundTop: {
		height: 450,
		width: '100%',
		background: theme.palette.primary.main
	},
	foreground: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		paddingTop: theme.spacing(4)
	},
	foregroundContent: {
		width: 380,
		background: '#fff',
		margin: 'auto',
		padding: '0 45px 25px 45px',
		minHeight: 510,
		boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
		[theme.breakpoints.down('xs')]: {
			width: 360
		}
	},
	hero: {
		padding: theme.spacing(4, 0, 2)
	},
	image: {
		maxWidth: '100%',
		height: 85,
		'&.large': {
			height: 200
		}
	}
	// foregroundFooter: {
	//     marginTop: 20
	// }
}));

export default ({
	pageTitle,
	image,
	imageSize = 'small',
	imageAlt,
	title,
	subtitle,
	children,
	// footer
	useHeader = true
}: SignupTemplateProps) => {
	const classes = useStyles();

	const imageClassname = clsx(classes.image, imageSize);

	return (
		<div className={classes.root}>
			<Meta title={pageTitle} />

			{useHeader && <Header />}

			<div className={classes.content}>
				<div className={classes.background}>
					<div className={classes.backgroundTop} />
				</div>
				<div className={classes.foreground}>
					<div className={classes.foregroundContent}>
						<Container maxWidth="sm" component="main" className={classes.hero}>
							{image && <img src={image} alt={imageAlt} className={imageClassname} />}

							{title && (
								<Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
									{title}
								</Typography>
							)}

							{subtitle && (
								<Typography component="h2" variant="h4" align="center" color="textPrimary">
									{subtitle}
								</Typography>
							)}
						</Container>

						{children}
					</div>
					{/* <div className={classes.foregroundFooter}>{footer}</div> */}
				</div>
			</div>
		</div>
	);
};
