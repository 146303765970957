import auth from "./auth";
import config from "./config";
import entries from "./entries";
import gifts from "./gifts";
import loading from "./loading";
import errors from "./errors";
import status from "./status";

export const rootReducer = (state: any, action: any) => {
    return {
        ...state,
        auth: auth(state.auth, action),
        config: config(state.config, action),
        entries: entries(state.entries, action),
        gifts: gifts(state.gifts, action),
        loading: loading(state.loading, action),
        errors: errors(state.errors, action),
        status: status(state.status, action)
    };
}

export default rootReducer;