import React, { Fragment } from "react";
import { Field, FieldProps } from 'formik';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
// import Debug from "../Debug";

// const useStyles = makeStyles((theme: Theme) => ({
//     root: {},
//     form: {},
//     textField: {},
// }));

export default (props: any) => {
    // const classes = useStyles();

    return (
        <Field {...props}>
            {({
                field, // { name, value, onChange, onBlur }
                // form, // touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
            }: FieldProps) => {
                const { validate, label, ...rest } = props; // Strip out invalid TextField props
                return (
                    <Fragment> 
                        {/* <Debug value={{touched, errors, dirty, isValid, status}}/> */}
                        {/* <TextField
                            {...field}
                            {...rest}
                            error={!!(meta.touched && meta.error)}
                            helperText={meta.touched && meta.error && meta.error}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    // onChange={handleChange('checkedB')}
                                    // value="checkedB"
                                    {...field}
                                    {...rest}
                                    color="primary"
                                />
                            }
                            label={label}
                        />
                        {/* {meta.touched && meta.error && meta.error} */}
                    </Fragment>
                );
            }}
          </Field>
    );
}
                                