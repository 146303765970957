import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

type NoRecordsProps = {
    className?: string;
    loading: boolean;
    records: any[] | null;
    message?: string;
    children?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {}
}));

export default ({ className, loading, records, message = 'No records found.', children }: NoRecordsProps) => {
    const classes = useStyles();
    const rootClassName = clsx(classes.root, className);

    return !loading && (!records || !records.length) ? (
        <Typography variant="body1" className={rootClassName}>
            {message}
            {children && children}
        </Typography>
    ) : null;
};
