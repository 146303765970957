import { Avatar, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import PrettyGreenLogo from 'assets/logos/PG-Logotype-Primary-White.svg';
import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStateContext } from 'store/configureStore';

// import Typography from "@material-ui/core/Typography";
type HeaderProps = {
    elevation?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        //     marginRight: theme.spacing(2)
    },
    // title: {
    //     textDecoration: "none",
    //     color: theme.palette.common.white
    // },
    title: {
        width: 200
    },
    logo: {
        paddingTop: 9,
        color: theme.palette.common.white,
        maxWidth: '100%',
        height: 'auto'
    },
    links: {
        flexGrow: 1,
        textAlign: 'right'
    },
    link: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        '&.active': {
            textDecoration: 'underline',
            textUnderlinePosition: 'under'
        }
    },
    avatar: {
        margin: theme.spacing(1),
        textTransform: 'uppercase',
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.main
    }
}));

export default ({ elevation = 1 }: HeaderProps) => {
    const classes = useStyles();
    const { state } = useStateContext();
    // const { state, actions } = useStateContext();
    const [mobileMenuEl, setMobileMenuEl] = React.useState(null);
    const [userMenuEl, setUserMenuEl] = React.useState(null);
    const { auth } = state;

    const displayName = auth.user ? auth.user.displayName || auth.user.businessName : 'User';
    const initial = displayName.charAt(0);
    const isOnboarded: boolean | undefined = auth.user ? auth.user.isOnboarded : false;

    const handleOpenMobileMenu = (event: any): void => {
        setMobileMenuEl(event.currentTarget);
    };

    const handleCloseMobileMenu = (): void => {
        setMobileMenuEl(null);
    };

    const handleOpenUserMenu = (event: any): void => {
        setUserMenuEl(event.currentTarget);
    };

    const handleCloseUserMenu = (): void => {
        setUserMenuEl(null);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={elevation}>
                <Toolbar color="primary">
                    <Hidden smUp>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Mobile Menu"
                            aria-controls="mobile-menu"
                            aria-haspopup="true"
                            onClick={handleOpenMobileMenu}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="user-menu"
                            anchorEl={mobileMenuEl}
                            keepMounted
                            open={Boolean(mobileMenuEl)}
                            onClose={handleCloseMobileMenu}
                        >
                            <MenuItem onClick={handleCloseMobileMenu} component={Link} to="/schedule">
                                Schedule
                            </MenuItem>
                            <MenuItem onClick={handleCloseMobileMenu} component={Link} to="/entries">
                                Entries
                            </MenuItem>
                            <MenuItem onClick={handleCloseMobileMenu} component={Link} to="/contact">
                                Contact
                            </MenuItem>
                        </Menu>
                    </Hidden>

                    <Link to="/" className={classes.title}>
                        <img src={PrettyGreenLogo} alt="Pretty Green" className={classes.logo} />
                    </Link>

                    <div className={classes.links}>
                        <Hidden xsDown>
                            {auth.isAuthenticated &&
                                isOnboarded && (
                                    <Fragment>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/schedule"
                                            className={classes.link}
                                            activeClassName="active"
                                        >
                                            Schedule
									</Button>

                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/entries"
                                            className={classes.link}
                                            activeClassName="active"
                                        >
                                            Entries
									</Button>
                                    </Fragment>
                                )}

                            <Button
                                color="inherit"
                                component={NavLink}
                                to="/contact"
                                className={classes.link}
                                activeClassName="active"
                            >
                                Contact
                            </Button>
                        </Hidden>
                    </div>

                    {auth.isAuthenticated &&
                        isOnboarded && (
                            <Fragment>
                                {/* <Button color="inherit" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                                {displayName}
                            </Button> */}
                                <Avatar
                                    className={classes.avatar}
                                    aria-controls="user-menu"
                                    aria-haspopup="true"
                                    onClick={handleOpenUserMenu}
                                >
                                    {initial}
                                </Avatar>
                                <Menu
                                    id="user-menu"
                                    anchorEl={userMenuEl}
                                    keepMounted
                                    open={Boolean(userMenuEl)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                                    <MenuItem onClick={handleCloseUserMenu} component={Link} to="/logout">
                                        Logout
									</MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                </Toolbar>
            </AppBar>
        </div>
    );
};
