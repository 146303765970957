import { makeStyles, Theme } from "@material-ui/core";
import { Address, User } from "@pretty-green/common";
import SunHeart from "assets/illustrations/characters/individual/png/Sun/PG-Character-Sun-Heart.png";
import clsx from "clsx";
import Button from "elements/Button";
import TextField from "elements/form/TextField";
import Row from "elements/Row";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStateContext, useStatus } from "store/configureStore";
import { LoadingStatus } from "store/reducers/status";
import { AuthType } from "store/types";
import SignupTemplate from "templates/SignupTemplate";
import * as Yup from "yup";

// import Debug from "elements/Debug";

interface FormValues {
    line1: string;
    line2: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
}

const AddressSchema = Yup.object().shape({
    line1: Yup.string().required("Required"),
    line2: Yup.string(),
    city: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    form: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    textField: {},
    formActions: {
        marginTop: theme.spacing(4),
    },
    row: {
        marginTop: theme.spacing(2),
    },
    rowTextField: {
        "&:first-child": {
            marginRight: theme.spacing(1),
        },
        "&:last-child": {
            marginLeft: theme.spacing(1),
        },
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
}));

export default () => {
    const classes = useStyles();
    const { state, actions } = useStateContext();
    const [status] = useStatus(AuthType.UpdateCurrentUser);
    // const [status, errors] = useStatus(AuthType.UpdateCurrentUser);
    const isWorking = status === LoadingStatus.Loading;
    const history = useHistory();

    const user: User = state.auth.user || ({} as User);
    const address: Address =
        user.address ||
        ({
            line1: "",
            line2: "",
            city: "",
            postcode: "",
            state: "",
            country: "Australia",
        } as Address);

    const initialValues: FormValues = {
        line1: address.line1,
        line2: address.line2 || "",
        city: address.city,
        postcode: address.postcode,
        state: address.state,
        country: address.country,
    };

    const handleSubmit = async (
        values: FormValues,
        formActions: any
    ): Promise<void> => {
        // console.log({ values, formActions });
        formActions.setSubmitting(false);
        await actions.auth.updateCurrentUser({
            address: values,
        });
        history.push("/signup/payment");
    };

    return (
        <SignupTemplate
            pageTitle="Where are you?"
            image={SunHeart}
            imageAlt="Pretty Green"
            title="Where are you?"
            subtitle="Let us know where you or your business is located."
        >
            {/* <Debug value={state.status}/> */}
            <Formik
                initialValues={initialValues}
                validationSchema={AddressSchema}
                onSubmit={handleSubmit}
                render={(formikBag) => (
                    <Form noValidate className={classes.form}>
                        <TextField
                            name="line1"
                            label="Address Line 1"
                            placeholder="Address Line 1"
                            required
                            className={classes.textField}
                            disabled={isWorking}
                            fullWidth
                        />
                        <TextField
                            name="line2"
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            className={classes.textField}
                            disabled={isWorking}
                            fullWidth
                        />
                        <Row className={classes.row}>
                            <TextField
                                name="city"
                                label="City"
                                placeholder="City"
                                required
                                className={clsx(
                                    classes.textField,
                                    classes.marginRight
                                )}
                                disabled={isWorking}
                                fullWidth
                            />
                            <TextField
                                name="postcode"
                                label="Postcode"
                                placeholder="Postcode"
                                required
                                className={clsx(
                                    classes.textField,
                                    classes.marginLeft
                                )}
                                disabled={isWorking}
                                fullWidth
                            />
                        </Row>
                        <Row className={classes.row}>
                            <TextField
                                name="state"
                                label="State"
                                placeholder="State"
                                required
                                className={clsx(
                                    classes.textField,
                                    classes.marginRight
                                )}
                                disabled={isWorking}
                                fullWidth
                            />
                            <TextField
                                name="country"
                                label="Country"
                                placeholder="Country"
                                required
                                className={clsx(
                                    classes.textField,
                                    classes.marginLeft
                                )}
                                disabled={isWorking}
                                fullWidth
                            />
                        </Row>
                        <div className={classes.formActions}>
                            <Button
                                label="Let's do it"
                                type="submit"
                                working={isWorking}
                            />
                        </div>
                    </Form>
                )}
            />
        </SignupTemplate>
    );
};
